import { Box, Card } from '@mui/material'
import Table from '../../../../../../../components/Table'
import { headCells } from './events'
import { useEffect, useState } from 'react'
import _ from 'lodash'

const AnswerTable = ({ answers }) => {
  const [order, setOrder] = useState('desc')
  const [sort, setSort] = useState('')
  const [dataList, setDataList] = useState(answers)
  const [searchText, setSearchText] = useState('')
  const [text, setText] = useState('')

  useEffect(() => {
    fetchData(sort, order, text, answers, setDataList)
  }, [sort, order, text, answers])

  return (
    <Box>
      <Card sx={{ minWidth: 275, mx: 3, mt: 3 }}>
        <Table
          isCheckBox={false}
          data={convertData(dataList)}
          headCells={headCells}
          page={1}
          allCount={answers.length}
          tablePage={0}
          isShowPagination={false}
          placeholder="ค้นหาคำตอบ"
          searchKey={'answers'}
          handleSearch={(text) =>
            fetchData(sort, order, text, answers, setDataList)
          }
          sort={sort}
          setSort={setSort}
          order={order}
          setOrder={setOrder}
          searchText={searchText}
          setSearchText={setSearchText}
          text={text}
          setText={setText}
          onDownload={'hidden'}
          hideFilter={true}
          toolBarBoxSx={{ alignItems: 'center !important' }}
          defaultSortId="no"
          tableTitle="สถิติคำตอบ"
          searchBoxSx={{ ml: 'auto' }}
        />
      </Card>
    </Box>
  )
}

const convertData = (data) => {
  return data.map((item) => {
    return {
      ...item,
    }
  })
}

export const fetchData = async (sort, order, search, data, setDataList) => {
  const filteredData = _.defaultTo(data, []).filter(
    (item) =>
      !search || item.answer.toLowerCase().includes(search.toLowerCase()),
  )

  const sortedData = filteredData.sort((a, b) => {
    let comparison = 0
    const key = sort || 'no'
    if (key === 'answer') {
      comparison = a.answer.localeCompare(b.answer, 'th')
    } else {
      comparison = a[key] - b[key]
    }
    return _.toUpper(order) === 'ASC' ? comparison : -comparison
  })
  setDataList(sortedData)
}

export default AnswerTable
