export const defaultFilter = {
  testDateCheck: false,
  testDateStart: null,
  testDateFinish: null,
  courseChecked: false,
  course: '',
  classChecked: false,
  class: '',
  distributionChecked: false,
  distribution: '',
  agentCodeChecked: false,
  agentCode: '',
  unitCodeChecked: false,
  unitCode: '',
  SRAMChecked: false,
  SRAM: '',
  RAMChecked: false,
  RAM: '',
  trainerChecked: false,
  trainer: '',
  statusChecked: false,
  status: {
    preTestChecked: false,
    postTestChecked: false,
  },
}
