import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const reportEContentConst = {
  COLUMN_NAME: {
    NO: 'no',
    E_CONTENT_ID: 'eContentId',
    CATEGORY: 'category',
    SUB_CATEGORY: 'subCategory',
    CONTENT_TYPE: 'contentType',
    NAME: 'name',
    DOWNLOAD: 'download',
    TAG: 'tag',
    ASSIGNEES: 'assignees',
    REMARK: 'remark',
    DISTRIBUTION: 'distribution',
    LEVEL_OF_LEARNER: 'levelOfLearner',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    STATUS: 'status',
    CONTENT_CREATED_AT: 'contentCreatedAt',
    CONTENT_CREATED_BY: 'contentCreatedBy',
    CONTENT_UPDATED_AT: 'contentUpdatedAt',
    CONTENT_UPDATED_BY: 'contentUpdatedBy',
    //filter
    E_CONTENT_ID_FILTER: 'eContentIdFilter',
    KB_ID_FILTER: 'kbIdFilter',
    E_CONTENT_NAME: 'eContentName',
    KB_NAME: 'kbName',
    QUESTIONS: 'questions',
    ANSWER: 'answer',
  },
  COLUMN_NAME_TH: {
    NO: 'ลำดับ',
    E_CONTENT_ID: 'ID',
    CATEGORY: 'หมวดหมู่หลัก',
    SUB_CATEGORY: 'หมวดหมู่ย่อย',
    CONTENT_TYPE: 'ประเภทเนื้อหา',
    NAME: 'ชื่อ',
    DOWNLOAD: 'อนุญาตการดาวน์โหลดเนื้อหา',
    TAG: 'Tag',
    ASSIGNEES: 'ผู้ร่วมจัดทำ',
    REMARK: 'หมายเหตุ',
    DISTRIBUTION: 'ช่องทางการจำหน่าย',
    LEVEL_OF_LEARNER: 'ระดับของผู้เรียน',
    START_DATE: 'วันเริ่มต้น',
    END_DATE: 'วันสิ้นสุด',
    STATUS: 'สถานะ',
    CONTENT_CREATED_AT: 'วันที่สร้าง',
    CONTENT_CREATED_BY: 'สร้างโดย',
    CONTENT_UPDATED_AT: 'วันที่แก้ไขล่าสุด',
    CONTENT_UPDATED_BY: 'แก้ไขล่าสุดโดย',
    //filter
    E_CONTENT_ID_FILTER: 'eContent ID',
    KB_ID_FILTER: 'KB ID',
    E_CONTENT_NAME: 'ชื่อบทความ',
    KB_NAME: 'ชื่อสาระน่ารู้',
    QUESTIONS: 'คำถาม',
    ANSWER: 'คำตอบ',
  },
  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT_BOX: 'INPUT_TEXT_BOX',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
  },
  downloadOptions: [
    { label: 'อนุญาต', value: true },
    { label: 'ไม่อนุญาต', value: false },
  ],
  statusOptions: [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' },
  ],
  contentTypeOptions: [
    { label: 'บทความ', value: 'บทความ' },
    { label: 'สาระน่ารู้', value: 'สาระน่ารู้' },
  ],
}

export const reportEContentDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportEContentConst.COLUMN_NAME.NO,
      value: reportEContentConst.COLUMN_NAME.NO,
      label: reportEContentConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 2,
      name: reportEContentConst.COLUMN_NAME.E_CONTENT_ID,
      value: reportEContentConst.COLUMN_NAME.E_CONTENT_ID,
      label: reportEContentConst.COLUMN_NAME_TH.E_CONTENT_ID,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportEContentConst.COLUMN_NAME.CATEGORY,
      value: reportEContentConst.COLUMN_NAME.CATEGORY,
      label: reportEContentConst.COLUMN_NAME_TH.CATEGORY,
      minWidth: 160,
    },
    {
      id: 4,
      name: reportEContentConst.COLUMN_NAME.SUB_CATEGORY,
      value: reportEContentConst.COLUMN_NAME.SUB_CATEGORY,
      label: reportEContentConst.COLUMN_NAME_TH.SUB_CATEGORY,
      minWidth: 160,
    },
    {
      id: 5,
      name: reportEContentConst.COLUMN_NAME.CONTENT_TYPE,
      value: reportEContentConst.COLUMN_NAME.CONTENT_TYPE,
      label: reportEContentConst.COLUMN_NAME_TH.CONTENT_TYPE,
      minWidth: 160,
    },
    {
      id: 6,
      name: reportEContentConst.COLUMN_NAME.NAME,
      value: reportEContentConst.COLUMN_NAME.NAME,
      label: reportEContentConst.COLUMN_NAME_TH.NAME,
      minWidth: 160,
    },
    {
      id: 7,
      name: reportEContentConst.COLUMN_NAME.DOWNLOAD,
      value: reportEContentConst.COLUMN_NAME.DOWNLOAD,
      label: reportEContentConst.COLUMN_NAME_TH.DOWNLOAD,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportEContentConst.COLUMN_NAME.TAG,
      value: reportEContentConst.COLUMN_NAME.TAG,
      label: reportEContentConst.COLUMN_NAME_TH.TAG,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportEContentConst.COLUMN_NAME.ASSIGNEES,
      value: reportEContentConst.COLUMN_NAME.ASSIGNEES,
      label: reportEContentConst.COLUMN_NAME_TH.ASSIGNEES,
      minWidth: 160,
    },
    {
      id: 10,
      name: reportEContentConst.COLUMN_NAME.REMARK,
      value: reportEContentConst.COLUMN_NAME.REMARK,
      label: reportEContentConst.COLUMN_NAME_TH.REMARK,
      minWidth: 160,
    },
    {
      id: 11,
      name: reportEContentConst.COLUMN_NAME.DISTRIBUTION,
      value: reportEContentConst.COLUMN_NAME.DISTRIBUTION,
      label: reportEContentConst.COLUMN_NAME_TH.DISTRIBUTION,
      minWidth: 160,
    },
    {
      id: 12,
      name: reportEContentConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      value: reportEContentConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      label: reportEContentConst.COLUMN_NAME_TH.LEVEL_OF_LEARNER,
      minWidth: 160,
    },
    {
      id: 13,
      name: reportEContentConst.COLUMN_NAME.START_DATE,
      value: reportEContentConst.COLUMN_NAME.START_DATE,
      label: reportEContentConst.COLUMN_NAME_TH.START_DATE,
      minWidth: 160,
    },
    {
      id: 14,
      name: reportEContentConst.COLUMN_NAME.END_DATE,
      value: reportEContentConst.COLUMN_NAME.END_DATE,
      label: reportEContentConst.COLUMN_NAME_TH.END_DATE,
      minWidth: 160,
    },
    {
      id: 15,
      name: reportEContentConst.COLUMN_NAME.STATUS,
      value: reportEContentConst.COLUMN_NAME.STATUS,
      label: reportEContentConst.COLUMN_NAME_TH.STATUS,
      minWidth: 160,
    },
    {
      id: 16,
      name: reportEContentConst.COLUMN_NAME.CONTENT_CREATED_AT,
      value: reportEContentConst.COLUMN_NAME.CONTENT_CREATED_AT,
      label: reportEContentConst.COLUMN_NAME_TH.CONTENT_CREATED_AT,
      minWidth: 160,
    },
    {
      id: 17,
      name: reportEContentConst.COLUMN_NAME.CONTENT_CREATED_BY,
      value: reportEContentConst.COLUMN_NAME.CONTENT_CREATED_BY,
      label: reportEContentConst.COLUMN_NAME_TH.CONTENT_CREATED_BY,
      minWidth: 160,
    },
    {
      id: 18,
      name: reportEContentConst.COLUMN_NAME.CONTENT_UPDATED_AT,
      value: reportEContentConst.COLUMN_NAME.CONTENT_UPDATED_AT,
      label: reportEContentConst.COLUMN_NAME_TH.CONTENT_UPDATED_AT,
      minWidth: 160,
    },
    {
      id: 19,
      name: reportEContentConst.COLUMN_NAME.CONTENT_UPDATED_BY,
      value: reportEContentConst.COLUMN_NAME.CONTENT_UPDATED_BY,
      label: reportEContentConst.COLUMN_NAME_TH.CONTENT_UPDATED_BY,
      minWidth: 160,
    },
  ],
  filterColumnOptions: [
    {
      id: 1,
      name: reportEContentConst.COLUMN_NAME.E_CONTENT_NAME,
      value: reportEContentConst.COLUMN_NAME.E_CONTENT_NAME,
      label: reportEContentConst.COLUMN_NAME_TH.E_CONTENT_NAME,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 2,
      name: reportEContentConst.COLUMN_NAME.KB_NAME,
      value: reportEContentConst.COLUMN_NAME.KB_NAME,
      label: reportEContentConst.COLUMN_NAME_TH.KB_NAME,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 3,
      name: reportEContentConst.COLUMN_NAME.CATEGORY,
      value: reportEContentConst.COLUMN_NAME.CATEGORY,
      label: reportEContentConst.COLUMN_NAME_TH.CATEGORY,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportEContentConst.COLUMN_NAME.CATEGORY,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 4,
      name: reportEContentConst.COLUMN_NAME.SUB_CATEGORY,
      value: reportEContentConst.COLUMN_NAME.SUB_CATEGORY,
      label: reportEContentConst.COLUMN_NAME_TH.SUB_CATEGORY,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportEContentConst.COLUMN_NAME.SUB_CATEGORY,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 5,
      name: reportEContentConst.COLUMN_NAME.DOWNLOAD,
      value: reportEContentConst.COLUMN_NAME.DOWNLOAD,
      label: reportEContentConst.COLUMN_NAME_TH.DOWNLOAD,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportEContentConst.downloadOptions,
    },
    {
      id: 6,
      name: reportEContentConst.COLUMN_NAME.TAG,
      value: reportEContentConst.COLUMN_NAME.TAG,
      label: reportEContentConst.COLUMN_NAME_TH.TAG,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportEContentConst.COLUMN_NAME.TAG,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 7,
      name: reportEContentConst.COLUMN_NAME.DISTRIBUTION,
      value: reportEContentConst.COLUMN_NAME.DISTRIBUTION,
      label: reportEContentConst.COLUMN_NAME_TH.DISTRIBUTION,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportEContentConst.COLUMN_NAME.DISTRIBUTION,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 8,
      name: reportEContentConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      value: reportEContentConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      label: reportEContentConst.COLUMN_NAME_TH.LEVEL_OF_LEARNER,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportEContentConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 9,
      name: reportEContentConst.COLUMN_NAME.STATUS,
      value: reportEContentConst.COLUMN_NAME.STATUS,
      label: reportEContentConst.COLUMN_NAME_TH.STATUS,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportEContentConst.statusOptions,
    },
    {
      id: 10,
      name: reportEContentConst.COLUMN_NAME.QUESTIONS,
      value: reportEContentConst.COLUMN_NAME.QUESTIONS,
      label: reportEContentConst.COLUMN_NAME_TH.QUESTIONS,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.INPUT_TEXT_BOX,
    },
    {
      id: 11,
      name: reportEContentConst.COLUMN_NAME.ANSWER,
      value: reportEContentConst.COLUMN_NAME.ANSWER,
      label: reportEContentConst.COLUMN_NAME_TH.ANSWER,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.INPUT_TEXT_BOX,
    },
    {
      id: 12,
      name: reportEContentConst.COLUMN_NAME.CONTENT_CREATED_AT,
      value: reportEContentConst.COLUMN_NAME.CONTENT_CREATED_AT,
      label: reportEContentConst.COLUMN_NAME_TH.CONTENT_CREATED_AT,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 13,
      name: reportEContentConst.COLUMN_NAME.CONTENT_CREATED_BY,
      value: reportEContentConst.COLUMN_NAME.CONTENT_CREATED_BY,
      label: reportEContentConst.COLUMN_NAME_TH.CONTENT_CREATED_BY,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 14,
      name: reportEContentConst.COLUMN_NAME.CONTENT_UPDATED_AT,
      value: reportEContentConst.COLUMN_NAME.CONTENT_UPDATED_AT,
      label: reportEContentConst.COLUMN_NAME_TH.CONTENT_UPDATED_AT,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 15,
      name: reportEContentConst.COLUMN_NAME.CONTENT_UPDATED_BY,
      value: reportEContentConst.COLUMN_NAME.CONTENT_UPDATED_BY,
      label: reportEContentConst.COLUMN_NAME_TH.CONTENT_UPDATED_BY,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 16,
      name: reportEContentConst.COLUMN_NAME.START_DATE,
      value: reportEContentConst.COLUMN_NAME.START_DATE,
      label: reportEContentConst.COLUMN_NAME_TH.START_DATE,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 17,
      name: reportEContentConst.COLUMN_NAME.END_DATE,
      value: reportEContentConst.COLUMN_NAME.END_DATE,
      label: reportEContentConst.COLUMN_NAME_TH.END_DATE,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 18,
      name: reportEContentConst.COLUMN_NAME.E_CONTENT_ID_FILTER,
      value: reportEContentConst.COLUMN_NAME.E_CONTENT_ID_FILTER,
      label: reportEContentConst.COLUMN_NAME_TH.E_CONTENT_ID_FILTER,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 19,
      name: reportEContentConst.COLUMN_NAME.KB_ID_FILTER,
      value: reportEContentConst.COLUMN_NAME.KB_ID_FILTER,
      label: reportEContentConst.COLUMN_NAME_TH.KB_ID_FILTER,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 20,
      name: reportEContentConst.COLUMN_NAME.CONTENT_TYPE,
      value: reportEContentConst.COLUMN_NAME.CONTENT_TYPE,
      label: reportEContentConst.COLUMN_NAME_TH.CONTENT_TYPE,
      minWidth: 160,
      type: reportEContentConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportEContentConst.contentTypeOptions,
    },
  ],
}
