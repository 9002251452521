import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'
//import { getEEVAReportQuestionCount } from '../../services/reportComp'

export const reportEEvaRawDataByCourseConst = {
  COLUMN_NAME: {
    NO: 'no',
    NAME: 'name',
    COURSE_NAME_EN: 'courseNameEN',
    COURSE_NAME_TH: 'courseNameTH',
    OVERALL_PART1: 'overallPercentagePart1',
    OVERALL_PART2: 'overallPercentagePart2',
    OVERALL_PART3: 'overallPercentagePart3',
    OVERALL_PART4: 'overallPercentagePart4',
    RESPONDENT_COUNT: 'respondentCount',
    ATTENDEE_COUNT: 'attendeeCount',
    CLASS_COUNT: 'classCount',
    CLASS_LEVEL: 'classLevel',
    COURSE_CODE: 'courseCode',
    EVA_ID: 'evaId',
    EVA_NAME: 'evaName',
    EVA_TYPE: 'evaType',
    TRAINING_DATE: 'trainingDate',
    START_TRAINING_DATE: 'startTrainingDate',
    END_TRAINING_DATE: 'endTrainingDate',
    COURSE_START_DATE: 'courseStartDate',
    STATION: 'station',
    TRAINER_ID: 'trainerId',
    TRAINER_NAME: 'trainerName',
    COUNT_QUESTION: 'countQuestion',
    COUNT_OPEN: 'countOpen',
    COUNT_ENROLL: 'countEnroll',
    COUNT_ATTENDANCE: 'countAttendance',
    PASS: 'pass',
    FAIL: 'fail',
    ABSENT: 'absent',
    MEAN_SCORE: 'meanScore',
    MIN_SCORE: 'minScore',
    MAX_SCORE: 'maxScore',
    ROADMAP: 'roadMap',
    ABBREVIATION_ROADMAP: 'abbreviationRoadMap',
    LEVEL_OF_LEARNER: 'levelOfLearner',
    OTHER_LEVEL_OF_LEARNER: 'otherLevelOfLearner',
    REQUIRED_DOCUMENT: 'requiredDocument',
    ACQUIRED_SKILL: 'acquiredSkill',
    TRAINER_STAFF_ID: 'trainerStaffID',
    VERSION: 'courseVersion',
    REQUIRE_DOCUMENT: 'requireDocument',
    STATUS_CLASS: 'statusClass',
    TRAININGPLATFORM: 'trainingPlatform',
    AGENT_TYPE: 'agentType',
    COURSE_NAME_FOR_LEARNER: 'courseNameForLearner',
    DISTRIBUTION: 'distribution',
    ABBREVIATION_DISTRIBUTION: 'abbreviationDistribution',
    E_TESTING_TESTNAME: 'eTestingTestName',
    RAM_CODE: 'ramCode',
    RAM_NAME: 'ramName',
    UNIT_CODE: 'unitCode',
    UNIT_NAME: 'unitName',
    DEPARTMENT: 'department',
  },

  COLUMN_NAME_TH: {
    NO: 'ลำดับ',
    NAME: 'ชื่อแบบทดสอบ',
    COURSE_NAME_EN: 'ชื่อหลักสูตร EN',
    COURSE_NAME_TH: 'ชื่อหลักสูตร TH',
    OVERALL_PART1: 'ภาพรวม ตอนที่ 1',
    OVERALL_PART2: 'ภาพรวม ตอนที่ 2',
    OVERALL_PART3: 'ภาพรวม ตอนที่ 3',
    OVERALL_PART4: 'ภาพรวม ตอนที่ 4',
    RESPONDENT_COUNT: 'จำนวนผู้ตอบแบบประเมิน',
    ATTENDEE_COUNT: 'จำนวนผู้เข้าอบรม',
    CLASS_COUNT: 'จำนวนคลาส',
    CLASS_LEVEL: 'ระดับ Class',
    COURSE_CODE: 'รหัสหลักสูตร',
    EVA_ID: 'รหัสแบบประเมิน',
    EVA_NAME: 'ชื่อแบบประเมิน',
    EVA_TYPE: 'ประเภทแบบประเมิน',
    TRAINING_DATE: 'Training Date',
    START_TRAINING_DATE: 'วันเริ่มต้น (Training Date)',
    END_TRAINING_DATE: 'วันสิ้นสุด (Training Date)',
    COURSE_START_DATE: 'วันเริ่มต้นหลักสูตร',
    STATION: 'Station',
    TRAINER_ID: 'Trainer (Staff ID)',
    TRAINER_NAME: 'Trainer Name',
    COUNT_QUESTION: 'จำนวนคำถาม',
    COUNT_OPEN: 'จำนวนเปิดรับสมัคร',
    COUNT_ENROLL: 'จำนวนรับสมัคร',
    COUNT_ATTENDANCE: 'จำนวนเข้าเรียน',
    PASS: 'ผ่าน',
    FAIL: 'ไม่ผ่าน',
    ABSENT: 'ไม่ทำ',
    MEAN_SCORE: 'ค่าเฉลี่ยคะแนน',
    MIN_SCORE: 'คะแนน Min',
    MAX_SCORE: 'คะแนน Max',
    ROADMAP: 'กลุ่มหลักสูตร',
    ABBREVIATION_ROADMAP: 'ชื่อย่อของกลุ่มหลักสูตร',
    LEVEL_OF_LEARNER: 'ระดับของผู้เรียน',
    OTHER_LEVEL_OF_LEARNER: 'ระดับของผู้เรียนของทางการจำหน่ายอื่น',
    REQUIRED_DOCUMENT: 'เอกสารการสมัคร',
    ACQUIRED_SKILL: 'ระดับหลักสูตร',
    TRAINER_STAFF_ID: 'Trainer Staff ID',
    TRAININGPLATFORM: 'รูปแบบการสอน',
    AGENT_TYPE: 'Agent Type',
    COURSE_NAME_FOR_LEARNER: 'ชื่อหลักสูตร (สำหรับ Learner)',
    DISTRIBUTION: 'ช่องทางการจัดจำหน่าย',
    ABBREVIATION_DISTRIBUTION: 'ชื่อย่อของช่องทางการจัดจำหน่าย',
    E_TESTING_TESTNAME: 'ชื่อแบบทดสอบ',
    VERSION: 'เวอร์ชันหลักสูตร',
    RAM_CODE: 'Ram Code',
    RAM_NAME: 'Ram Name',
    UNIT_CODE: 'Unit Code',
    UNIT_NAME: 'Unit Name',
    DEPARTMENT: 'Department',
  },

  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
  },

  courseLevelControlOptions: [
    { label: 'ระดับสูง', value: 'ระดับสูง', courseLevelControl: 'ระดับสูง' },
    { label: 'ระดับกลาง', value: 'ระดับกลาง', courseLevelControl: 'ระดับกลาง' },
    { label: 'ระดับต่ำ', value: 'ระดับต่ำ', courseLevelControl: 'ระดับต่ำ' },
  ],

  approvalOptions: [
    {
      label: 'มีการอนุมัติ',
      value: true,
    },
    {
      label: 'ไม่มีการอนุมัติ',
      value: false,
    },
  ],

  isAvailableOptions: [
    {
      label: 'มี',
      value: true,
    },
    {
      label: 'ไม่มี',
      value: false,
    },
  ],

  requireOptions: [
    {
      label: 'ต้องการ',
      value: true,
    },
    {
      label: 'ไม่ต้องการ',
      value: false,
    },
  ],

  isAttendanceOptions: [
    {
      label: 'กำหนด',
      value: true,
    },
    {
      label: 'ไม่กำหนด',
      value: false,
    },
  ],

  statusOptions: [
    {
      label: 'Unassign',
      value: 'UNASSIGN',
    },
    {
      label: 'Todo',
      value: 'TODO',
    },
    {
      label: 'Open register',
      value: 'OPENREGISTRATION',
    },
    {
      label: 'Closed register',
      value: 'CLOSEREGISTRATION',
    },
    {
      label: 'In progress',
      value: 'INPROGRESS',
    },
    {
      label: 'Start class',
      value: 'STARTCLASS',
    },

    {
      label: 'End class',
      value: 'ENDCLASS',
    },

    {
      label: 'Closed class',
      value: 'CLOSED',
    },
    {
      label: 'Cancelled',
      value: 'CANCELED',
    },
  ],

  PlatformOptions: [
    {
      value: 'CLASSROOM',
      label: 'Classroom',
    },
    {
      value: 'ONLINE',
      label: 'Online',
    },
    {
      value: 'ELEARNING',
      label: 'E-Learning',
    },
  ],

  agentTypeOptions: [
    {
      value: 'ALLTYPE',
      label: 'All Type',
    },
    {
      value: 'A',
      label: 'A',
    },
    {
      value: 'L',
      label: 'L',
    },
  ],
  evaTypeOptions: [
    {
      value: 'CLASS',
      label: 'Class',
    },
    {
      value: 'STANDALONE',
      label: 'Standalone',
    },
  ],
}

export const reportEEvaRawDataByCourseDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.NO,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.NO,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.NO,
    },
    {
      id: 2,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.EVA_NAME,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.EVA_NAME,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.EVA_NAME,
      minWidth: 160,
    },
    {
      id: 5,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.RESPONDENT_COUNT,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.RESPONDENT_COUNT,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.RESPONDENT_COUNT,
      minWidth: 160,
    },
    {
      id: 6,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.ATTENDEE_COUNT,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.ATTENDEE_COUNT,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.ATTENDEE_COUNT,
      minWidth: 160,
    },
    {
      id: 7,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.CLASS_COUNT,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.CLASS_COUNT,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.CLASS_COUNT,
      minWidth: 160,
    },
    {
      id: 4,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.OVERALL_PART1,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.OVERALL_PART1,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.OVERALL_PART1,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.OVERALL_PART2,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.OVERALL_PART2,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.OVERALL_PART2,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.OVERALL_PART3,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.OVERALL_PART3,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.OVERALL_PART3,
      minWidth: 160,
    },
    {
      id: 10,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.OVERALL_PART4,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.OVERALL_PART4,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.OVERALL_PART4,
      minWidth: 160,
    },
  ],

  filterColumnOptions: [
    {
      id: 1,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.STATION,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.STATION,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.STATION,
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportEEvaRawDataByCourseConst.COLUMN_NAME.STATION,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 2,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 3,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.COURSE_NAME_TH,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.COURSE_NAME_TH,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.COURSE_NAME_TH,
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.COURSE_CODE,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.COURSE_CODE,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.COURSE_CODE,
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 5,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.EVA_ID,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.EVA_ID,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.EVA_ID,
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 7,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.VERSION,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.VERSION,
      label: 'เวอร์ชันหลักสูตร',
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 8,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.START_TRAINING_DATE,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.START_TRAINING_DATE,
      label: 'วันเริ่มต้น (Training Date)',
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 9,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.END_TRAINING_DATE,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.END_TRAINING_DATE,
      label: 'วันสิ้นสุด (Training Date)',
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 26,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.COURSE_START_DATE,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.COURSE_START_DATE,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.COURSE_START_DATE,
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 10,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
      label:
        reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.COURSE_NAME_FOR_LEARNER,
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 11,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.TRAINER_ID,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.TRAINER_ID,
      label: 'Trainer (Staff ID)',
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 12,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.TRAINER_NAME,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.TRAINER_NAME,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.TRAINER_NAME,
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 14,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.DISTRIBUTION,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.DISTRIBUTION,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.DISTRIBUTION,
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportEEvaRawDataByCourseConst.COLUMN_NAME.DISTRIBUTION,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 15,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME
        .ABBREVIATION_DISTRIBUTION,
      value:
        reportEEvaRawDataByCourseConst.COLUMN_NAME.ABBREVIATION_DISTRIBUTION,
      label:
        reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.ABBREVIATION_DISTRIBUTION,
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportEEvaRawDataByCourseConst.COLUMN_NAME.DISTRIBUTION,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 22,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.EVA_NAME,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.EVA_NAME,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.EVA_NAME,
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 27,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.EVA_TYPE,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.EVA_TYPE,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.EVA_TYPE,
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.INPUT_TEXT,
      options: reportEEvaRawDataByCourseConst.evaTypeOptions,
    },
    {
      id: 28,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.RAM_CODE,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.RAM_CODE,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.RAM_CODE,
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 29,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.RAM_NAME,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.RAM_NAME,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.RAM_NAME,
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 30,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.UNIT_CODE,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.UNIT_CODE,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.UNIT_CODE,
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 31,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.UNIT_NAME,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.UNIT_NAME,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.UNIT_NAME,
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 32,
      name: reportEEvaRawDataByCourseConst.COLUMN_NAME.DEPARTMENT,
      value: reportEEvaRawDataByCourseConst.COLUMN_NAME.DEPARTMENT,
      label: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH.DEPARTMENT,
      minWidth: 160,
      type: reportEEvaRawDataByCourseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportEEvaRawDataByCourseConst.COLUMN_NAME.DEPARTMENT,
      fetchOptions: () => fetchMasterDataOptions(),
    },
  ],
}

// export const HandleEEVAList = async () => {
//   const newDefault = reportEEvaRawDataByCourseDefaultColumn.defaultColumns
//   //const questionCount = store.dispatch(getEEVAReportQuestionCount())
//   const questionCount = 5
//   newDefault.push({
//     id: 4,
//     name: ``,
//     value: ``,
//     label: `คะแนนความพึงพอใจที่ได้`,
//     minWidth: 160,
//   })
//   for (let i = 0; i < questionCount; i++) {
//     newDefault.push({
//       id: 4 + i,
//       name: `review${i + 1}`,
//       value: `review${i + 1}`,
//       label: `คำถามข้อที่ ${i + 1}`,
//       minWidth: 160,
//     })
//   }
//   newDefault.push(
//     {
//       id: 5 + questionCount,
//       name: `totalReview`,
//       value: `totalReview`,
//       label: `ภาพรวมที่ได้`,
//       minWidth: 160,
//     },
//     {
//       id: 6 + questionCount,
//       name: `countNumber`,
//       value: `countNumber`,
//       label: `จำนวนผู้ตอบแบบสอบถาม`,
//       minWidth: 160,
//     },
//     {
//       id: 7 + questionCount,
//       name: `countClass`,
//       value: `countClass`,
//       label: `จำนวนคลาสที่สอน`,
//       minWidth: 160,
//     },
//     {
//       id: 8 + questionCount,
//       name: ``,
//       value: ``,
//       label: `แบบประเมินหลักสูตร`,
//       minWidth: 160,
//     },
//   )
//   for (let i = 0; i < questionCount; i++) {
//     newDefault.push({
//       id: 9 + i,
//       name: `courseReview${i + 1}`,
//       value: `courseReview${i + 1}`,
//       label: `คำถามข้อที่ ${i + 1}`,
//       minWidth: 160,
//     })
//   }
//   newDefault.push(
//     {
//       id: 10 + questionCount,
//       name: `courseRemark`,
//       value: `courseRemark`,
//       label: `ข้อเสนอแนะ ทั้งหมด`,
//       minWidth: 160,
//     },
//     {
//       id: 11 + questionCount,
//       name: `totalCourseReview`,
//       value: `totalCourseReview`,
//       label: `ภาพรวมที่ได้`,
//       minWidth: 160,
//     },
//     {
//       id: 12 + questionCount,
//       name: ``,
//       value: ``,
//       label: `แบบประเมินการบริหารจัดการหลักสูตร`,
//       minWidth: 160,
//     },
//   )
//   for (let i = 0; i < questionCount; i++) {
//     newDefault.push({
//       id: 13 + i,
//       name: `manageCourseReview${i + 1}`,
//       value: `manageCourseReview${i + 1}`,
//       label: `คำถามข้อที่ ${i + 1}`,
//       minWidth: 160,
//     })
//   }
//   newDefault.push(
//     {
//       id: 13 + questionCount,
//       name: `manageCourseRemark`,
//       value: `manageCourseRemark`,
//       label: `ข้อเสนอแนะ ทั้งหมด`,
//       minWidth: 160,
//     },
//     {
//       id: 14 + questionCount,
//       name: `totalCourseReview`,
//       value: `totalCourseReview`,
//       label: `ภาพรวมที่ได้`,
//       minWidth: 160,
//     },
//     {
//       id: 15 + questionCount,
//       name: ``,
//       value: ``,
//       label: `แบบสอบถามอื่นๆ`,
//       minWidth: 160,
//     },
//   )
//   for (let i = 0; i < questionCount; i++) {
//     newDefault.push({
//       id: 16 + i,
//       name: `otherQuestion${i + 1}`,
//       value: `otherQuestion${i + 1}`,
//       label: `คำถามข้อที่ ${i + 1}`,
//       minWidth: 160,
//     })
//   }
//   newDefault.push(
//     {
//       id: 16 + questionCount,
//       name: `manageCourseRemark`,
//       value: `manageCourseRemark`,
//       label: `ข้อเสนอแนะ ทั้งหมด`,
//       minWidth: 160,
//     },
//     {
//       id: 17 + questionCount,
//       name: `totalCourseReview`,
//       value: `totalCourseReview`,
//       label: `ภาพรวมที่ได้`,
//       minWidth: 160,
//     },
//   )
//   return newDefault
// }
