import _ from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSearchText } from '../../../../../../../../../redux/slices/table'
// import { onEnterSearch } from './events'
import { BoxSearch, SearchInput, StyledSearchIcon } from './Styled'
import { handleQuickSearch } from '../../../../../handler/handleQuickSearch'
import { mutateFilterPropToBody } from '../../../mutation/filterPropToBody'

const SearchCustom = () => {
  const dispatch = useDispatch()
  const { table, search } = useSelector((state) => state.table)
  const tableProps = table

  const handleSearch = (text) => {
    dispatch(handleQuickSearch(tableProps, text, mutateFilterPropToBody))
  }

  return (
    <BoxSearch sx={{ marginLeft: 'auto' }}>
      <SearchInput
        data-testid="search-input"
        placeholder={search.placeholder}
        onChange={(e) => dispatch(setSearchText(e.target.value))}
        onKeyPress={(e) => {
          if (e.charCode === 13) {
            handleSearch(e.target.value)
          }
          // dispatch(onEnterSearch(e, handleSearch))
        }}
        value={_.get(search, 'searchText', '')}
        inputProps={{
          maxLength: search?.maxLength > 0 ? search.maxLength : 255,
        }}
      />
      <StyledSearchIcon
        data-testid="search-icon"
        color="primary"
        onClick={() => handleSearch(search.searchText)}
      />
    </BoxSearch>
  )
}

export default SearchCustom
