import dayjs from 'dayjs'
import _ from 'lodash'
import { versionStatus } from '../../../../constants/eTesting'
import { versionHeadCells } from '../../../../constants/eEvaluation'
import {
  resetTablePage,
  setFilterProp,
  setInitialMore,
  setInitialTable,
  setSearchText,
  setStatusFilter,
  setFilterTotal,
  startLoading,
  stopLoading,
} from '../../../../redux/slices/table'
import { convertFormatDateTime } from '../../../../utils/lib'
import {
  eEvaluationVersionFilter,
  eEvaluationVersionDownload,
} from '../../../../utils/apiPath'
import { downloadFile } from '../../../../services/util'
import { initialState } from '../../../../redux/slices/table/model'
import callAxios from '../../../../utils/baseService'
import { path } from '../../../../constants/path'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    type: 'CUSTOM',
    list: selected,
  }

  await dispatch(
    downloadFile({
      url: eEvaluationVersionDownload,
      body: body,
      fileName: `รายการเวอร์ชั่นE-Evaluation.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const fetchDataList =
  (
    method,
    table,
    page,
    statusList,
    filterProp,
    keyword,
    codeId,
    handleSearch,
    setInitial,
  ) =>
  async (dispatch) => {
    dispatch(startLoading())
    const defaultSort = _.get(table, 'defaultSort.state', undefined)
    const isInitial = method == 'initial'
    const tableProps = isInitial ? initialState.table : table
    const filter = isInitial ? null : filterProp
    const sortId =
      handleSearch !== undefined && handleSearch === null
        ? 'version'
        : defaultSort == 0
        ? 'version'
        : tableProps.sort
    const version = parseFloat(_.get(filter, 'version', -1))
    const sortBy = tableProps.sort == 'versionStatus' ? 'status' : sortId
    const realPage = page <= 0 ? 1 : +page
    const title = isInitial ? '' : _.get(filter, 'name', keyword)
    const startDate = _.get(filter, 'startDate', undefined)
    const toDate = _.get(filter, 'toDate', undefined)
    const body = {
      version: !_.isString(filter?.version) && version === 0 ? -1 : version,
      name: method == 'filter' ? title : keyword,
      codeId: codeId ?? 'index',
      startDate: startDate
        ? dayjs(startDate).format(window.__env__.REACT_APP_DATE_DB)
        : '',
      toDate: toDate
        ? dayjs(toDate).format(window.__env__.REACT_APP_DATE_DB)
        : '',
      createdBy: _.get(filter, 'createdBy', ''),
      updatedBy: _.get(filter, 'updatedBy', ''),
      limit: tableProps.limit,
      order: tableProps.order.toUpperCase(),
      page: realPage,
      sort: sortBy,
      status: isInitial ? [] : statusList,
    }

    const modifiedHeadCells = await versionHeadCells.map((item) => {
      const viewPath =
        item.id === 'actionEVersion'
          ? `${path.eEvaluation}/dashboard/version/view`
          : undefined
      const hideEdit = item.id === 'actionEVersion' ? true : undefined
      return {
        ...item,
        viewPath,
        hideEdit,
      }
    })

    const isCancel = await callAxios
      .post(eEvaluationVersionFilter, body)
      .then(({ data }) => {
        const rows = data.result.map((item) => {
          item.updatedBy = item.updatedBy.name
          return {
            ...item,
            startDate: convertFormatDateTime({
              value: item.startDate,
              type: 'date',
            }),
            versionStatus: versionStatus[item.status],
          }
        })
        dispatch(
          setInitialTable({
            rows: rows,
            allCount: data.totalCount,
            headCells: modifiedHeadCells,
            placeholder: 'ค้นหาชื่อแบบประเมิน',
            searchKey: 'name',
            status: statusList,
            canFilter: true,
            handleSearch: (text) =>
              dispatch(handleQuickSearch(tableProps, text, codeId)),
            // onDownload: (selected, sort, order) =>
            //   dispatch(onDownload(selected, sort, order)),
          }),
        )
        dispatch(
          setInitialMore({
            eEvaluation: data.eEvaluation,
            isAvailable: data.eEvaluation.status == 'ACTIVE',
            isDisable: !rows.some((data) => data.status == 'ACTIVE'),
            uuid: data.eEvaluation.uuid,
          }),
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: versionHeadCells,
          }),
        )
        return e.message?.includes('method')
      })

    setInitial && setInitial(false)
    if (!isCancel) dispatch(stopLoading())
  }

export const onFilterClick = (table, filter, codeId) => (dispatch) => {
  dispatch(setSearchText(''))
  const statusList = []
  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status.inActiveChecked) {
      statusList.push('INACTIVE')
    }
    if (filter.status.pendingChecked) {
      statusList.push('WAITING')
    }
    if (filter.status.deletedChecked) {
      statusList.push('DELETED')
    }
    if (filter.status.draftChecked) {
      statusList.push('DRAFT')
    }
  }
  dispatch(setStatusFilter(statusList))

  let startDate = ''
  let toDate = ''
  if (filter.startDateChecked) {
    if (filter.displayDate) {
      startDate = filter.startDate
      toDate = filter.toDate
    }
  }

  let version = -1
  if (filter.versionChecked) {
    if (filter.version == '') {
      version = 0
    } else {
      version = filter.version
    }
  }

  const filterText = {
    version: version,
    name: filter.nameChecked ? filter.name : '',
    startDate: startDate,
    toDate: toDate,
    createdBy: filter.createdByChecked ? filter.createdBy : '',
    updatedBy: filter.updatedByChecked ? filter.updatedBy : '',
  }
  dispatch(setFilterProp(filterText))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(
    fetchDataList(
      'filter',
      table,
      1,
      statusList,
      filterText,
      filterText.name,
      codeId,
      undefined,
    ),
  )
}

export const handleQuickSearch = (table, text, codeId) => (dispatch) => {
  dispatch(setSearchText(text))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  const statusList = []
  dispatch(
    fetchDataList(
      'search',
      table,
      1,
      statusList,
      null,
      text,
      codeId,
      undefined,
    ),
  )
}
