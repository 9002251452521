import React, { useMemo, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { getPlainText } from 'src/utils/lib'
import { CropperDialog } from '../UploadCropper/components/CropperDialog'
import { handleInsertImage } from './events'
import { modulesProp } from './model'
import { StyledEditorBox } from './Styled'

const QuillEditor = ({
  id,
  boxSx,
  required,
  disabled,
  readOnly,
  isTextLefe,
  labelText,
  value,
  textError,
  textInfo,
  showMaxLength,
  onChange,
}) => {
  const isOpen = useSelector(
    (state) => state.uploadCropper.isOpen,
    shallowEqual,
  )
  const [imageLocalSrc, setImageLocalSrc] = useState(undefined)
  const plainText = getPlainText(value)
  const quillRef = useRef()
  const modules = useMemo(
    () => ({
      ...modulesProp,
      toolbar: {
        ...modulesProp.toolbar,
        handlers: { image: () => handleInsertImage(setImageLocalSrc) },
      },
    }),
    [],
  )

  return (
    <>
      <StyledEditorBox
        sx={{ ...boxSx }}
        textError={textError}
        disabled={disabled}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box display="flex">
            <Typography
              variant="body2"
              color={disabled ? 'text.gray' : 'text.secondary'}
            >
              {labelText}
            </Typography>
            {required && (
              <Typography
                sx={{ lineHeight: 1, ml: 0.5 }}
                color={disabled ? 'text.gray' : 'error'}
              >
                *
              </Typography>
            )}
          </Box>
        </Box>

        <ReactQuill
          ref={quillRef}
          theme="snow"
          id={id}
          readOnly={readOnly || disabled}
          modules={modules}
          onChange={onChange}
          value={value}
        />

        <Box
          sx={{
            mb: 3,
            display: 'flex',
            justifyContent: isTextLefe ? 'flex-start' : 'space-between',
          }}
        >
          <Box>
            {!textError && textInfo && textInfo.length > 0 && (
              <Typography variant="body2" color="text.lightGray">
                {textInfo}
              </Typography>
            )}
            {textError && textError.length > 0 && (
              <Typography variant="body2" color="error">
                {textError}
              </Typography>
            )}
          </Box>
          {showMaxLength > 0 && (
            <Typography
              sx={{
                height: '25px',

                textAlign: 'end',
                color:
                  plainText.length > showMaxLength ? 'error.main' : 'unset',
              }}
              variant="body2"
            >
              {`${plainText.length}/${showMaxLength}`}
            </Typography>
          )}
        </Box>
      </StyledEditorBox>
      {isOpen && (
        <CropperDialog
          folder={id}
          imageLocalSrc={imageLocalSrc}
          onChange={(key) => {
            const editor = quillRef.current.getEditor()
            const url = `${window.__env__.REACT_APP_API_URL}/file/get${key}`
            editor.insertEmbed(editor.getSelection(), 'image', url)
          }}
        />
      )}
    </>
  )
}

export default QuillEditor
