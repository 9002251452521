import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getFormatDate } from '../../../../../../../utils/lib'
import { BoxWrapper, FilterStyledBox, StyledClearIcon } from '../Styled'
import { handleClearFilter } from '../../handler/handleClearFilter'

export const FilterBox = () => {
  const { eEvaluationDashboard } = useSelector(
    (state) => ({
      eEvaluationDashboard: state.crud.eEvaluationDashboard,
    }),
    shallowEqual,
  )
  const startDateFilter = eEvaluationDashboard?.filtersDrawer?.startDate ?? ''
  const endDateFilter = eEvaluationDashboard?.filtersDrawer?.endDate ?? ''
  const hasFilter = startDateFilter && endDateFilter
  const isFilterDate = eEvaluationDashboard?.filtersDrawer?.isFilterDate ?? ''
  return (
    <>
      {hasFilter ? (
        <BoxWrapper>
          {startDateFilter && endDateFilter && (
            <FilterStyledBox>
              วันที่:{' '}
              <strong>
                {getFormatDate(startDateFilter)} -{' '}
                {getFormatDate(endDateFilter)}
              </strong>
              {isFilterDate && (
                <StyledClearIcon
                  onClick={() => handleClearFilter('filterDate')}
                />
              )}
            </FilterStyledBox>
          )}
        </BoxWrapper>
      ) : (
        ''
      )}
    </>
  )
}
