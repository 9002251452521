import { store } from '../../../../../../App'
import { toggleEvaluationDrawer } from './handleClickOpenDrawer'
import { setFieldValue } from '../../../../../../redux/slices/crud'
import { partTitle } from '../models/partModel'
import _ from 'lodash'
import { questionTypeText } from '../constants/type'

export const handleClickViewButton = (type, index = null) => {
  toggleEvaluationDrawer(true)
  console.log('get content', type)
  if (type === 'part1') {
    setEvaDrawer('title', partTitle.part1)
    setEvaDrawer('drawerType', 'part1')
    setEvaDrawer('dataType', 'Rating')
    // fetch data
    const demoData = [
      {
        question: 'ข้อที่ 1 คุณภาพเนื้อหาเป็นอย่างไร',
        attendeeCount: 20,
        respondentCount: 10,
        ratingPercentage: 30.0,
        overallPercentage: 40.5,
        data: [5, 4, 3, 0, 2], //ดีมาก ดี ปานกลาง พอใจ ควรปรับปรุง
      },
      {
        question: 'ข้อที่ 2 ความยาวเนื้อหาเป็นอย่างไร',
        attendeeCount: 100,
        respondentCount: 95,
        ratingPercentage: 80.0,
        overallPercentage: 95.0,
        data: [50, 4, 16, 20, 10],
      },
    ]
    setEvaDrawer('part1.data', demoData)
  } else if (type === 'part2') {
    const { eEvaluationDashboard } = store.getState().crud
    const trainerData = _.get(
      eEvaluationDashboard,
      `overview.trainerEva.[${index}]`,
      null,
    )
    setEvaDrawer('title', `${partTitle.part2} : ${trainerData?.title ?? ''}`)
    setEvaDrawer('drawerType', 'part2')
    setEvaDrawer('dataType', 'Rating')
    //fetch data with trainer
    const data = []
    if (!_.isNil(trainerData)) {
      data.push({
        question: 'ข้อที่ 1 ความพึงพอใจต่อ performance',
        attendeeCount: 20,
        respondentCount: 10,
        ratingPercentage: 30.0,
        overallPercentage: 40.5,
        data: [5, 4, 3, 0, 2], //ดีมาก ดี ปานกลาง พอใจ ควรปรับปรุง
      })
      data.push({
        question: 'ข้อที่ 2 ความสามารถทางวาจา',
        attendeeCount: 100,
        respondentCount: 95,
        ratingPercentage: 80.0,
        overallPercentage: 95.0,
        data: [50, 4, 16, 20, 10],
      })
    }
    setEvaDrawer('part2.data', data)
  } else if (type === 'part3') {
    setEvaDrawer('title', partTitle.part3)
    setEvaDrawer('drawerType', 'part3')
    setEvaDrawer('dataType', 'Rating')
    // fetch data
    const demoData = [
      {
        question: 'ข้อที่ 1 ความยาวเนื้อหาเป็นอย่างไร',
        attendeeCount: 20,
        respondentCount: 10,
        ratingPercentage: 30.0,
        overallPercentage: 40.5,
        data: [5, 4, 3, 0, 2], //ดีมาก ดี ปานกลาง พอใจ ควรปรับปรุง
      },
      {
        question: 'ข้อที่ 2 การคลอบคลุมของหลักสูตร',
        attendeeCount: 100,
        respondentCount: 95,
        ratingPercentage: 80.0,
        overallPercentage: 95.0,
        data: [50, 4, 16, 20, 10],
      },
    ]
    setEvaDrawer('part3.data', demoData)
  } else if (type === 'part4') {
    const { eEvaluationDashboard } = store.getState().crud
    const part4EvaData = _.get(
      eEvaluationDashboard,
      `overview.part4Eva.[${index}]`,
      null,
    )
    const type = part4EvaData?.type
    setEvaDrawer('title', part4EvaData?.title)
    setEvaDrawer('drawerType', 'part4')
    setEvaDrawer('dataType', type)
    console.log(part4EvaData)

    const data = []
    if (!_.isNil(part4EvaData)) {
      if (type === questionTypeText.FREE_TEXT) {
        console.log('fetch data for free text')
        data.push({
          no: 1,
          title: 'คำตอบที่ 1',
          answerTxt: `"Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."
"There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain..." "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."
"There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain..."`,
        })
        data.push({
          no: 2,
          title: 'คำตอบที่ 2',
          answerTxt: `Answer 2`,
        })
        data.push({
          no: 3,
          title: 'คำตอบที่ 3',
          answerTxt: `Answer 3`,
        })
        data.push({
          no: 4,
          title: 'คำตอบที่ 4',
          answerTxt: `Answer 4`,
        })
      } else {
        console.log('fetch data for ' + type)
        //fetch data for each
        data.push({
          no: 1,
          question: 'ข้อที่ 1 คุณภาพอุปกรณ์เป็นอย่างไร',
          attendeeCount: 200,
          respondentCount: 180,
          overallPercentage: 80,
          data: [
            { no: 1, count: 54, percent: 30, answer: 'Answer 1' },
            { no: 2, count: 36, percent: 20, answer: 'Answer 2' },
            { no: 3, count: 48, percent: 10, answer: 'Answer 3' },
            { no: 4, count: 12, percent: 7, answer: 'Answer 4' },
            { no: 5, count: 10, percent: 6, answer: 'Answer 5' },
            { no: 6, count: 10, percent: 6, answer: 'Answer 6' },
            { no: 7, count: 10, percent: 6, answer: 'Answer 7' },
            { no: 8, count: 10, percent: 6, answer: 'Answer 8' },
            { no: 9, count: 10, percent: 6, answer: 'Answer 9' },
            { no: 10, count: 10, percent: 6, answer: 'Answer 10' },
          ],
        })
        data.push({
          no: 2,
          question: 'ข้อที่ 2 คุณภาพอุปกรณ์เป็นอย่างไร ครั้งที่ 2',
          attendeeCount: 220,
          respondentCount: 200,
          overallPercentage: 80,
          data: [
            { no: 1, count: 54, percent: 30, answer: 'Answer 1.2' },
            { no: 2, count: 30, percent: 20, answer: 'Answer 2.2' },
            { no: 3, count: 40, percent: 10, answer: 'Answer 3.2' },
            { no: 4, count: 10, percent: 7, answer: 'Answer 4.2' },
            { no: 5, count: 10, percent: 6, answer: 'Answer 5.2' },
            { no: 6, count: 10, percent: 6, answer: 'Answer 6.2' },
            { no: 7, count: 10, percent: 6, answer: 'Answer 7.2' },
            { no: 8, count: 10, percent: 6, answer: 'Answer 8.2' },
            { no: 9, count: 10, percent: 6, answer: 'Answer 9.2' },
            { no: 10, count: 10, percent: 6, answer: 'Answer 10.2' },
          ],
        })
      }
    }
    setEvaDrawer('part4.selectedNo', 1)
    setEvaDrawer('part4.type', type)
    setEvaDrawer('part4.data', data)
  }
}

export const setEvaDrawer = (key, value) => {
  store.dispatch(
    setFieldValue({
      key: `eEvaluationDashboard.evaluationDrawer.${key}`,
      value: value,
    }),
  )
}
