import { crudConfig as initialCrudConfig } from './crudConfig'
import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import CFilterDrawer from '../../../../../../components/CRUD/components/FilterDrawer'
import { filterItems } from './model/filterItems'
import { defaultFilter } from './model/defaultFilter'
import { handleChange } from '../../../../../../components/CRUD/handler/handleChange'
import { handleFiltering } from '../../../../../../components/CRUD/handler/handleFiltering'

import { initialConfig } from '../../../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { mutateFilterPropToBody } from './mutation/filterPropToBody'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import dayjs from 'dayjs'
import { FilterBox } from '../filterBox'
import {
  LoadingCircular,
  // StyledCard,
  StyledContainer,
} from '../../../../../Styled'
import { Box, Button, Card, Divider, Grid, Typography } from '@mui/material'
import { FilterListOutlined } from '@mui/icons-material'
import {
  setCustomToolbar,
  setCustomToolbarSx,
  setToggleFilterDrawer,
} from '../../../../../../redux/slices/table'

import { Row, StyledContentBox, StyledToolbar } from '../Styled'
import InfoCard from './components/InfoCard'
import BarChart from '../../../../../../components/Chart/BarChart'
import { CContainer } from '../../../../../../components/CRUD/components/Container'
import { fetchDashboardTable } from '../../../../../../components/CRUD/handler/fetchDashboardTable'
import { useDispatch } from 'react-redux'
import CustomEnhancedTableToolbar from './components/CustomEnhanceTableToolbar'
import ViewString from '../testTab/components/ViewString'
import ClassDrawer from './components/ClassDrawer'
import { openCourseDrawer } from '../../../../../../redux/slices/eLearning/dashboard'

export const OverviewTab = ({ tab }) => {
  const dispatch = useDispatch()
  const { eTestingDashboard, crudConfig, isLoading } = useSelector(
    (state) => state.crud,
  )
  const { filterTotal } = useSelector(
    (state) => ({
      filterTotal: state.table.filter.filterTotal,
    }),
    shallowEqual,
  )
  useEffect(() => {
    initialConfig(initialCrudConfig)
  }, [])

  useEffect(() => {
    if (!_.isUndefined(crudConfig) && tab === 'overview') {
      dispatch(setCustomToolbar(<CustomEnhancedTableToolbar />))
      dispatch(setCustomToolbarSx({ width: '100%' }))
      fetchDashboardTable(mutateFilterPropToBody, {}, 'overview').then()
    }
  }, [crudConfig])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }
  const latestUpdated = _.get(eTestingDashboard, 'lastestUpdated', '')

  const labels = _.get(eTestingDashboard, 'overview.scoreBarChartLabels', [])
  const dataSet = {
    data: _.get(eTestingDashboard, 'overview.scoreBarChartData', []),
    backgroundColor: '#4040AB',
  }

  console.log('render overviewTab')

  return (
    <StyledContainer>
      <Card sx={{ minWidth: 275 }}>
        <StyledContentBox sx={{ display: 'grid' }}>
          <StyledToolbar sx={{}}>
            <Typography variant="h6">ผลการทำข้อสอบ</Typography>
            <CFilterDrawer
              handleChange={handleChange}
              handleFiltering={() =>
                handleFiltering(
                  mutateFilterSateToProp,
                  mutateFilterPropToBody,
                  'overview',
                )
              }
              filterItems={filterItems}
              defaultFilter={defaultFilter}
            />

            <Box sx={{ display: 'flex', gap: '16px' }}>
              <Button
                data-testid={'btn-filter'}
                sx={{ height: 40, p: 1 }}
                variant="text"
                startIcon={<FilterListOutlined />}
                onClick={() => dispatch(setToggleFilterDrawer(true))}
              >
                ตัวกรอง {filterTotal != 0 && `(${filterTotal})`}
              </Button>
            </Box>
          </StyledToolbar>
          <FilterBox />
          <Typography variant="body2">
            ข้อมูลเมื่อวันที่{' '}
            {latestUpdated ?? dayjs().format('DD/MM/YYYY 04:00')}
          </Typography>
          <Row sx={{ gap: '16px' }}>
            <InfoCard
              label="คะแนนเฉลี่ย"
              number={`${_.get(
                eTestingDashboard,
                'overview.meanScore',
                0,
              )}/100`}
              unit="คะแนน"
            />
            <InfoCard
              label="จำนวนคำถาม"
              number={`${_.get(
                eTestingDashboard,
                'overview.totalQuestion',
                0,
              )}`}
              unit="คำถาม"
            />
          </Row>
          <Row sx={{ height: '362px', gap: '16px' }}>
            <Grid
              item
              container
              direction="column"
              sx={{ width: '46px', height: '362px', flexWrap: 'nowrap' }}
            >
              <Grid item xs={2}>
                <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                  จำนวนผู้ได้คะแนน
                </Typography>
              </Grid>
              <Grid item xs={9} justifyContent="center" alignItems="center">
                <Divider
                  orientation="vertical"
                  variant="middle"
                  sx={{ width: '1px', margin: 'auto' }}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                  คะแนน
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ width: '596px', height: '100%' }}>
              <BarChart dataSet={dataSet} labels={labels} />
            </Box>
            <Box sx={{ width: '100%', height: '362px' }}>
              <CContainer contentSx={{ mx: 0 }} cardSx={{ mt: 0 }} />
            </Box>
          </Row>
          <Divider variant="middle" />
          <Typography variant="h6">สถิติเพิ่มเติม</Typography>
          <Row sx={{ gap: '16px' }}>
            <InfoCard
              label="จำนวนผู้เรียนทั้งหมด"
              number={`${_.get(
                eTestingDashboard,
                'overview.countTotalStudent',
                0,
              )}`}
              unit="คน"
              isOnClick={true}
              onClick={() => {
                dispatch(
                  openCourseDrawer({
                    header: 'จำนวนผู้เรียนทั้งหมด',
                    drawerType: 'eTestingDashboard',
                    courseType: 'ALL',
                  }),
                )
              }}
            />
            <InfoCard
              label="จำนวนผู้ไม่เข้าเรียน"
              number={`${_.get(eTestingDashboard, 'overview.countAbsent', 0)}`}
              unit="คน"
              isOnClick={true}
              onClick={() => {
                dispatch(
                  openCourseDrawer({
                    header: 'จำนวนผู้ไม่เข้าเรียน',
                    drawerType: 'eTestingDashboard',
                    courseType: 'ABSENT',
                  }),
                )
              }}
            />
            <InfoCard
              label="จำนวนผู้ทำแบบทดสอบ"
              number={`${_.get(eTestingDashboard, 'overview.countDoTest', 0)}`}
              unit="คน"
              isOnClick={true}
              onClick={() => {
                dispatch(
                  openCourseDrawer({
                    header: 'จำนวนผู้ทำแบบทดสอบ',
                    drawerType: 'eTestingDashboard',
                    courseType: 'DOTEST',
                  }),
                )
              }}
            />
            <InfoCard
              label="จำนวนผู้ไม่ทำแบบทดสอบ"
              number={`${_.get(eTestingDashboard, 'overview.countNotTest', 0)}`}
              unit="คน"
              isOnClick={true}
              onClick={() => {
                dispatch(
                  openCourseDrawer({
                    header: 'จำนวนผู้ไม่ทำแบบทดสอบ',
                    drawerType: 'eTestingDashboard',
                    courseType: 'MISSTEST',
                  }),
                )
              }}
            />
            <InfoCard
              label="จำนวนผ่าน"
              number={`${_.get(
                eTestingDashboard,
                'overview.countPass',
                0,
              )}/${_.get(eTestingDashboard, 'overview.countTotal', 0)}`}
              unit="คน"
              isOnClick={true}
              onClick={() => {
                dispatch(
                  openCourseDrawer({
                    header: 'จำนวนผ่าน',
                    drawerType: 'eTestingDashboard',
                    courseType: 'PASS',
                  }),
                )
              }}
            />
            <InfoCard
              label="จำนวนไม่ผ่าน"
              number={`${_.get(
                eTestingDashboard,
                'overview.countFail',
                0,
              )}/${_.get(eTestingDashboard, 'overview.countTotal', 0)}`}
              unit="คน"
              isOnClick={true}
              onClick={() => {
                dispatch(
                  openCourseDrawer({
                    header: 'จำนวนไม่ผ่าน',
                    drawerType: 'eTestingDashboard',
                    courseType: 'FAIL',
                  }),
                )
              }}
            />
          </Row>
          <Row sx={{ gap: '16px' }}>
            <InfoCard
              label="จำนวนการทำข้อสอบ Max"
              number={`${_.get(
                eTestingDashboard,
                'overview.maxCountDoTest',
                0,
              )}`}
              unit="ครั้ง"
            />
            <InfoCard
              label="จำนวนการทำข้อสอบ Max"
              number={`${_.get(
                eTestingDashboard,
                'overview.minCountDoTest',
                0,
              )}`}
              unit="ครั้ง"
            />
            <InfoCard
              label="จำนวนครั้ง Avg"
              number={`${_.get(
                eTestingDashboard,
                'overview.avgCountDoTest',
                0,
              )}`}
              unit="ครั้ง"
            />
          </Row>
        </StyledContentBox>
      </Card>
      <Card>
        <StyledContentBox sx={{ display: 'grid' }}>
          <Typography variant="h6">คำถามที่ตอบผิดมากที่สุด</Typography>
          <InfoCard
            label="จำนวนการผิดจากคำตอบทั้งหมด"
            number={`${_.get(
              eTestingDashboard,
              'overview.countMostWrongQuestion',
              0,
            )}/${_.get(eTestingDashboard, 'overview.countTotalQuestion', 0)}`}
            unit="ครั้ง"
          />
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <Typography sx={{ padding: '4px' }}>คำถาม</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '8px', pl: '4px' }}>
            <Typography variant="h6" fontSize="20px" sx={{ height: '100%' }}>
              Q{_.get(eTestingDashboard, 'overview.mostWrongNumber', 0)}.
            </Typography>
            <Typography variant="h6" fontSize="20px">
              {_.get(eTestingDashboard, 'overview.mostWrongQuestion', 0)}
            </Typography>
          </Box>
          <Divider variant="middle" sx={{ paddingTop: '24px' }} />
          <Box
            sx={{ display: 'flex', gap: '16px', paddingTop: '24px', pl: '4px' }}
          >
            <Typography>คำตอบ</Typography>
          </Box>
          <Box sx={{ paddingTop: '24px', pl: '4px' }}>
            {_.get(eTestingDashboard, `overview.answer`, []).map((data, i) => {
              return (
                <Box
                  key={i}
                  sx={{ display: 'flex', gap: '8px', paddingTop: '24px' }}
                >
                  <ViewString
                    value={data?.answerText}
                    customSx={{ width: '50%' }}
                  />
                  <Box sx={{ display: 'flex', gap: '8px', width: '50%' }}>
                    <Box
                      sx={{
                        height: '100%',
                        width: data?.answerPercent,
                        backgroundColor: data?.isRightAnswer
                          ? '#1CC54E'
                          : '#C9143280',
                        borderRadius: '4px',
                      }}
                    />
                    <Typography
                      sx={{ p: '4px', whiteSpace: 'nowrap', margin: 'auto 0' }}
                    >
                      {data?.answerCount} ({data?.answerPercent})
                    </Typography>
                  </Box>
                </Box>
              )
            })}
          </Box>
        </StyledContentBox>
      </Card>
      <ClassDrawer />
      <LoadingCircular isLoading={isLoading} />
    </StyledContainer>
  )
}
