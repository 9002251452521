import dayjs from 'dayjs'

export const arrayCheckbox = [
  {
    label: 'วันที่',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    placeholder: 'เลือกวันที่',
    type: 'daterange',
  },
  {
    label: 'Course Name',
    nameCheckBox: 'courseNameChecked',
    nameInput: 'courseName',
    type: 'select_dropdown',
    placeholder: 'เลือก Course Name',
  },
  {
    label: 'Training Date',
    nameCheckBox: 'trainingDateChecked',
    nameInput: 'trainingDate',
    type: 'select_dropdown',
    placeholder: 'เลือก Training Date',
  },
  {
    label: 'Trainer Name',
    nameCheckBox: 'trainerNameChecked',
    nameInput: 'trainerName',
    type: 'select_dropdown',
    placeholder: 'เลือก Trainer Name',
  },
  {
    label: 'Distribution Channel',
    nameCheckBox: 'distributionChecked',
    nameInput: 'distribution',
    type: 'select_dropdown',
    placeholder: 'เลือก Distribution Channel',
  },
]

export const defaultFilter = {
  dateChecked: true,
  startDate: new Date(dayjs().startOf('Month').format('YYYY-MM-DD')),
  endDate: new Date(dayjs().format('YYYY-MM-DD')),
  displayDate: true,
  courseNameChecked: false,
  courseName: '',
  trainingDateChecked: false,
  trainingDate: '',
  trainerNameChecked: false,
  trainerName: '',
  distributionChecked: false,
  distribution: '',
}
