import { CRUDListFilterPath } from '../../../../../../utils/apiPath'
import { headerCells } from './model/headerCells'

export const crudConfig = {
  moduleId: 'ETESTING_DASHBOARD_TESTERLIST',
  moduleSlug: 'e-testing-dashboard-testerList',
  moduleTitleFull: 'Dashboard',
  modulesTitleShort: 'Dashboard',
  modulePath: '/e-testing/dashboard/view/:uuid',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
}
