import { store } from '../../../../../../App'
import { setFieldValue } from '../../../../../../redux/slices/crud'
import {
  filtersDrawerEnum,
  overviewEnum,
  evaluationDrawerEnum,
} from '../enums/drawerEnum'
import { fetchOverview } from './fetchOverview'

export const initialize = async () => {
  store.dispatch(
    setFieldValue({
      key: 'eEvaluationDashboard.filtersDrawer',
      value: filtersDrawerEnum,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'eEvaluationDashboard.overview',
      value: overviewEnum,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'eEvaluationDashboard.evaluationDrawer',
      value: evaluationDrawerEnum,
    }),
  )
  await fetchOverview()
}
