import { useSelector } from 'react-redux'
import { setOrder } from '../../../../../../../redux/slices/table'
import { FormControl, MenuItem, Select } from '@mui/material'
import { store } from '../../../../../../../App'
import { useEffect } from 'react'

export const SortDropdown = () => {
  const { sort } = useSelector((state) => state.table.table.sort)
  useEffect(() => {
    store.dispatch(setOrder({ order: 'wrongDESC', sort: 'wrongDESC' }))
  }, [])

  return (
    <FormControl>
      <Select
        labelId="select-label"
        id="simple-select"
        value={sort}
        defaultValue="wrongDESC"
        onChange={(e) =>
          store.dispatch(
            setOrder({ order: e.target.value, sort: e.target.value }),
          )
        }
      >
        <MenuItem value={'wrongDESC'}>ตอบผิด : สูง-ต่ำ</MenuItem>
        <MenuItem value={'correctDESC'}>ตอบถูก : สูง-ต่ำ</MenuItem>
        <MenuItem value={'questionNumber'}>ลำดับคำถาม</MenuItem>
      </Select>
    </FormControl>
  )
}
