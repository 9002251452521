import {
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/eEvaluationDetail'
import { fetchDataForm } from '../../../Form/event'

export const fetchEEvaluation = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  await dispatch(fetchDataForm({ uuid, isClone: false, setOnEdit: () => {} }))
  dispatch(stopLoading())
}
