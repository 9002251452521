import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import dayjs from 'dayjs'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { TYPE_OF_VALUE } from '../../../../constants/reportCourseSetting/reportCourseSetting'
import TextInput from '../../../Input/TextInput'
import DateRangeInput from '../../../DateRangeInput'
import SelectGroupChip from '../../../SelectGroupChip'
import Autocomplete from '../../../Input/Autocomplete'
import Select from '../../../Input/Select'
import { handleChangeFilterValue } from '../../handler/handleChangeFilterValue'
import { handleNumberInput } from '../../handler/handleNumberInput'

const ValueOfColumn = () => {
  const dispatch = useDispatch()
  const { columnSelected, value, error, options, moduleId } = useSelector(
    (state) => ({
      columnSelected: state.reportComp.columnSelected,
      value: state.reportComp.value,
      error: state.reportComp.error,
      options: state.reportComp.options,
      moduleId: state.reportComp.reportConfig.moduleId,
    }),
    shallowEqual,
  )

  return (
    <Box sx={{ width: '50%' }}>
      {columnSelected?.type === TYPE_OF_VALUE.DATE_RANGE && (
        <DateRangeInput
          labelText="ข้อมูล"
          placeholder="เลือกวันที่"
          dateState={{
            startDate: !value?.startDate ? new Date() : value?.startDate,
            endDate: !value?.endDate ? new Date() : value?.endDate,
            display: false,
            key: 'selection',
          }}
          onChange={(selectedDates) => {
            const startDate = dayjs(
              _.get(selectedDates, 'startDate', ''),
            ).format('YYYY-MM-DD')
            const endDate = dayjs(_.get(selectedDates, 'endDate', '')).format(
              'YYYY-MM-DD',
            )
            dispatch(
              handleChangeFilterValue({
                startDate: startDate,
                endDate: endDate,
              }),
            )
          }}
        />
      )}

      {(columnSelected?.type === TYPE_OF_VALUE.INPUT_NUMBER ||
        _.isNil(columnSelected)) && (
        <TextInput
          sxTextError={{ mb: 0 }}
          type="basic"
          name="value"
          labelText="ข้อมูล"
          disabled={_.isNil(columnSelected)}
          textError={error}
          isShowTextError={!_.isNull(error)}
          value={value || ''}
          inputProps={{
            type: 'text',
            onInput: (e) => {
              if (columnSelected?.type === TYPE_OF_VALUE.INPUT_NUMBER)
                handleNumberInput(e)
            },
          }}
          onChange={(event) => {
            const value = _.get(event, 'target.value', null)
            dispatch(handleChangeFilterValue(value))
          }}
        />
      )}

      {columnSelected?.type === TYPE_OF_VALUE.INPUT_TEXT_BOX && (
        <TextInput
          sxTextError={{ mb: 0 }}
          type="basic"
          name="value"
          labelText="ข้อมูล"
          textError={error}
          isShowTextError={!_.isNull(error)}
          value={value || ''}
          inputProps={{
            type: 'text',
          }}
          onChange={(event) => {
            const value = _.get(event, 'target.value', null)
            dispatch(handleChangeFilterValue(value))
          }}
        />
      )}

      {columnSelected?.type === TYPE_OF_VALUE.INPUT_TEXT && (
        <Autocomplete
          id="value"
          name="value"
          labelText="ข้อมูล"
          options={options}
          defaultValue={{
            value: value,
            label:
              ['trainerIdList', 'createdBy', 'updatedBy'].includes(
                columnSelected?.value,
              ) && moduleId === 'REPORT_MIX_MODULE'
                ? _.filter(options, (opt) => opt.value === value)?.[0]?.label ||
                  ''
                : value,
          }}
          isHideSort={true}
          renderInput={(params) => (
            <TextField placeholder="กรุณาเลือก" {...params} />
          )}
          handleChange={(e, obj) => {
            dispatch(
              handleChangeFilterValue(
                ['trainerIdList', 'createdBy', 'updatedBy'].includes(
                  columnSelected?.value,
                ) && moduleId === 'REPORT_MIX_MODULE'
                  ? obj?.value || ''
                  : obj?.label || '',
              ),
            )
          }}
        />
      )}

      {columnSelected?.type === TYPE_OF_VALUE.DROPDOWN_MULTI && (
        <SelectGroupChip
          boxSx={{ mb: 3 }}
          id="value"
          name="value"
          labelText="ข้อมูล"
          placeholder="กรุณาเลือก"
          options={options}
          value={value}
          onChange={(list) => dispatch(handleChangeFilterValue(list))}
          textError={error}
          isOptionDisabled={columnSelected?.isOptionDisabled}
        />
      )}

      {columnSelected?.type === TYPE_OF_VALUE.DROPDOWN_SINGLE && (
        <Select
          dataTestid="select-value"
          id="value"
          name="value"
          placeholder="กรุณาเลือก"
          labelText="ข้อมูล"
          options={options}
          value={!_.isNil(value) ? value : ''}
          defaultValue={value}
          handleChange={(e) => {
            const value = _.get(e, 'target.value', '')
            dispatch(handleChangeFilterValue(value))
          }}
        />
      )}
    </Box>
  )
}

export default ValueOfColumn
