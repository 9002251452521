import _ from 'lodash'
import {
  eLearningCourseBatchLearnerDownload,
  eLearningCourseBatchLearnerFilter,
} from '../../../../../../utils/apiPath'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setFilterTotal,
  startLoading,
  stopLoading,
} from '../../../../../../redux/slices/table'
import { setDateRageList } from '../../../../../../redux/slices/manageClassLearner'

import { downloadFile } from '../../../../../../services/util'
import { headCellsOICLearner } from '../../../../../../constants/eLearning'
import callAxios from '../../../../../../utils/baseService'
import { convertFormatDateTime } from '../../../../../../utils/lib'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  sort = sort === 'updatedAt' ? 'codeId' : sort
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    type: 'CUSTOM',
    list: selected,
  }

  await dispatch(
    downloadFile({
      url: eLearningCourseBatchLearnerDownload,
      body: body,
      fileName: `รายชื่อผู้เรียน.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const fetchDataList =
  (method, table, page, filterProp, keyword, uuid) => async (dispatch) => {
    dispatch(startLoading())

    let sortBy = table.sort === 'updatedAt' ? 'codeId' : table.sort
    if (method === 'create') {
      sortBy = 'updatedAt'
      page = 1
      filterProp = {}
      keyword = ''
    }
    const realPage = page <= 0 ? 1 : +page
    const title = _.get(filterProp, 'name', keyword)
    const attendance = _.get(filterProp, 'attendance', null)
    const body = {
      courseBatchUuid: uuid,
      codeId: _.get(filterProp, 'codeId', ''),
      name: method === 'filter' ? keyword : title,
      attendance: attendance ? Number(attendance) : null,
      trainingResult: _.get(filterProp, 'trainingResult', []),
      status: _.get(filterProp, 'status', []),
      startDate: _.get(filterProp, 'startDate', ''),
      finishDate: _.get(filterProp, 'finishDate', ''),
      limit: method == 'create' ? '20' : table.limit,
      order: method == 'create' ? 'DESC' : table.order.toString().toUpperCase(),
      page: realPage,
      sort: sortBy,
    }

    const isCancel = await callAxios
      .post(eLearningCourseBatchLearnerFilter, body)
      .then(({ data }) => {
        const rows = data.result.map((item) => ({
          ...item,
          prefixTH: item.userProfile.prefixTH || '-',
          firstNameTH: item.userProfile.firstNameTH || '-',
          lastNameTH: item.userProfile.lastNameTH || '-',
          statusLearner: item.status,
          isLearner: true,
          attendance: convertFormatDateTime({ type: 'dateTime', value: item.attendance }),
          classLearnerCourseStatus: handleLearnerResult(item),
          
        }))
        const dateRange = _.get(data, 'dateRange', []).map((item) => ({
          label: item,
          value: item,
        }))
        dispatch(setDateRageList(dateRange))
        dispatch(
          setInitialTable({
            rows,
            allCount: data.totalCount,
            headCells: headCellsOICLearner,
            placeholder: 'ค้นหาชื่อ หรือ นามสกุล',
            searchKey: 'name',
            handleSearch: (text) =>
              dispatch(handleQuickSearch(table, text, uuid)),
            onDownload: (selected, sort, order) =>
              dispatch(onDownload(selected, sort, order)),
            // onDelete: (row) => dispatch(handleDeleteModule(row, tableProps)),
          }),
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: headCellsOICLearner,
          }),
        )
        return e.message?.includes('method')
      })

    if (!isCancel) dispatch(stopLoading())
  }

export const handleLearnerResult = (item) => {
  const learnerResult = _.get(item, 'learnerResult', null)
  if (_.isNull(learnerResult)) return '-'
  return learnerResult === 'PASS' ? 'ผ่านการอบรม' : 'ไม่ผ่านการอบรม'
}

export const onFilterClick = (table, filter, uuid) => (dispatch) => {
  dispatch(setSearchText(''))

  // const resultList = handleFilterResult(filter)
  const statusList = handleFilterStatus(filter)

  const filterText = {
    name: filter.nameChecked ? filter.name : '',
    attendance: filter.attendanceChecked ? filter.attendance : null,
    startDate: filter.startDate ? filter.startDate : '',
    finishDate: filter.finishDate ? filter.finishDate : '',
    date: filter.roomChecked ? filter.date : '',
    status: statusList,
  }

  dispatch(setFilterProp(filterText))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(fetchDataList('filter', table, 1, filterText, filterText.name, uuid))
}

export const handleQuickSearch = (table, text, manageProps) => (dispatch) => {
  if (!text) {
    window.location.reload()
  } else {
    dispatch(setSearchText(text))
    dispatch(setFilterProp(null))
    dispatch(resetTablePage())
    dispatch(fetchDataList('search', table, 1, null, text, manageProps))
  }
}

export const checkColorStatus = (status, theme) => {
  const statusText = status?.toString().toLowerCase()

  const colorList = {
    eligible: theme?.palette?.success?.main,
    ineligible: theme?.palette?.error?.main,
    eligible_announce: theme?.palette?.success?.main,
    waiting_announce: theme?.palette?.warning?.light,
    waiting_check: theme?.palette?.text?.silver,
    rejected_document: theme?.palette?.error?.main,
    cancel: theme?.palette?.text?.white,
    canceled: theme?.palette?.text?.white,
  }
  return colorList[statusText]
}

export const handleFilterResult = (filter) => {
  const resultList = []
  if (filter.trainingResultChecked) {
    if (filter.trainingResult.passChecked) {
      resultList.push('PASS')
    }
    if (filter.trainingResult.failChecked) {
      resultList.push('FAIL')
    }
  }
  return resultList
}

export const handleFilterStatus = (filter) => {
  const statusList = []
  if (filter.statusChecked) {
    if (filter.status.eligibleChecked) {
      statusList.push('ELIGIBLE_ANNOUNCE')
    }
    if (filter.status.waitConfirmChecked) {
      statusList.push('WAITING_CONFIRM_IDENTITY')
    }
    if (filter.status.waitAnnounceChecked) {
      statusList.push('WAITING_ANNOUNCE')
    }
    if (filter.status.waitCheckChecked) {
      statusList.push('WAITING_CHECK')
    }
    if (filter.status.rejectedChecked) {
      statusList.push('REJECTED_DOCUMENT')
    }
    if (filter.status.cancelChecked) {
      statusList.push('CANCEL')
    }
    if (filter.status.canceledChecked) {
      statusList.push('CANCELED')
    }
    if (filter.status.ineligibleChecked) {
      statusList.push('INELIGIBLE')
    }
    if (filter.status.cancelClassChecked) {
      statusList.push('CANCEL_CLASS')
    }
  }
  return statusList
}
