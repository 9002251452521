// import callAxios from '../../../../../../utils/baseService'
import _ from 'lodash'
import { store } from '../../../../../../App'
import { setFieldValue } from '../../../../../../redux/slices/crud'
import { overviewEnum } from '../enums/drawerEnum'
// import { staffProfileDashboard } from '../../../../../../utils/apiPath'
import dayjs from 'dayjs'

export const fetchOverview = async () => {
  const { eEvaluationDashboard } = store.getState().crud
  const body = {
    startDate: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.startDate,
      dayjs().startOf('month').format('YYYY-MM-DD'),
    ),
    endDate: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.endDate,
      dayjs().format('YYYY-MM-DD'),
    ),
  }
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: true,
    }),
  )
  console.log(body)

  store.dispatch(
    setFieldValue({
      key: 'eEvaluationDashboard.overview',
      value: {
        ...overviewEnum,
        courseEva: {
          attendeeCount: 200,
          respondentCount: 180,
          overallPercentage: 90,
        },
        trainerEva: [
          {
            title: 'Trainer 1',
            type: 'Rating',
            contentId: 'trainerEva1',
            attendeeCount: 100,
            respondentCount: 95,
            overallPercentage: 95.0,
            data: [50, 4, 16, 20, 10],
          },
          {
            title: 'Trainer 2',
            type: 'Rating',
            contentId: 'trainerEva2',
            attendeeCount: 120,
            respondentCount: 85,
            overallPercentage: 80.0,
            data: [100, 4, 16, 20, 200],
          },
        ],
        courseManageEva: {
          attendeeCount: 50,
          respondentCount: 30,
          overallPercentagePart: 25.0,
        },
        part4Eva: [
          {
            title: 'แบบประเมิณอุปกรณ์',
            type: 'Rating',
            contentId: 'part4-1',
            totalAnswer: 50,
            attendeeCount: 40,
            respondentCount: 30,
            overallPercentage: 20.0,
            answer: [
              { no: 1, count: 1, percent: 20, answer: `Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...
There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain... Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...
There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain...` },
              { no: 2, count: 2, percent: 80, answer: 'Answer 2' },
              { no: 3, count: 3, percent: 70, answer: 'Answer 3' },
              { no: 4, count: 4, percent: 70, answer: 'Answer 4' },
              { no: 5, count: 5, percent: 70, answer: 'Answer 5' },
              { no: 6, count: 6, percent: 50, answer: 'Answer 6' },
              { no: 7, count: 7, percent: 70, answer: 'Answer 7' },
              { no: 8, count: 8, percent: 50, answer: 'Answer 8' },
              { no: 9, count: 9, percent: 70, answer: 'Answer 9' },
              { no: 10, count: 10, percent: 40, answer: 'Answer 10' },
            ],
          },
          {
            title: 'จะแนะนำหลักสูตรนี้ต่อหรือไม่',
            type: 'Single Choice',
            contentId: 'part4-2',
            totalAnswer: 60,
            attendeeCount: 80,
            respondentCount: 10,
            overallPercentage: 10.0,
            answer: [
              { no: 1, count: 1, percent: 20, answer: 'Answer 1' },
              { no: 2, count: 2, percent: 80, answer: 'Answer 2' },
              { no: 3, count: 3, percent: 750, answer: 'Answer 3' },
              { no: 4, count: 1, percent: 20, answer: 'Answer 1' },
              { no: 5, count: 2, percent: 80, answer: 'Answer 2' },
              { no: 6, count: 3, percent: 70, answer: 'Answer 3' },
              { no: 7, count: 4, percent: 70, answer: 'Answer 4' },
              { no: 8, count: 5, percent: 70, answer: 'Answer 5' },
              { no: 9, count: 6, percent: 50, answer: 'Answer 6' },
              { no: 10, count: 7, percent: 70, answer: 'Answer 7' },
              { no: 11, count: 8, percent: 50, answer: 'Answer 8' },
              { no: 12, count: 9, percent: 70, answer: 'Answer 9' },
              { no: 13, count: 10, percent: 40, answer: 'Answer 10' },
            ],
          },
          {
            title: 'จะแนะนำหลักสูตรนี้ต่อหรือไม่ 2',
            type: 'Multiple Choice',
            contentId: 'part4-3',
            totalAnswer: 50,
            attendeeCount: 50,
            respondentCount: 50,
            overallPercentage: 50.5,
            answer: [
              { no: 1, count: 1, percent: 20, answer: 'Answer 1' },
              { no: 2, count: 2, percent: 80, answer: 'Answer 2' },
              { no: 3, count: 3, percent: 750, answer: 'Answer 3' },
            ],
          },
          {
            title: 'คำแนะนำเพิ่มเติม',
            type: 'Free Text',
            contentId: 'part4-4',
            totalAnswer: 30,
            attendeeCount: 15,
            respondentCount: 20,
            overallPercentage: 60.333,
            answer: [
              { no: 1, count: 1, percent: 20, answer: 'Answer 1' },
              { no: 2, count: 2, percent: 80, answer: 'Answer 2' },
              { no: 3, count: 3, percent: 750, answer: 'Answer 3' },
            ],
          },
        ],
      },
    }),
  )

  // await callAxios
  //   .post(staffProfileDashboard, body)
  //   .then(({ data }) => {
  //     store.dispatch(
  //       setFieldValue({
  //         key: 'eEvaluationDashboard.latestUpdated',
  //         value: _.get(data, 'result.latestUpdated', undefined),
  //       }),
  //     )
  //   })
  //   .catch((err) => {
  //     console.log(err)
  //   })

  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: false,
    }),
  )
}
