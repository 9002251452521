import { crudConfig } from '../crudConfig'

export const headerCells = () => {
  return [
    {
      id: 'no',
      label: 'ลำดับ',
      width: '20px',
    },
    {
      id: 'score',
      label: 'คะแนน',
      disablePadding: false,
    },
    {
      id: 'scoreCount',
      label: 'จำนวนผู้ได้คะแนน (คน)',
      disablePadding: false,
    },
    {
      id: 'action',
      disablePadding: false,
      label: '',
      width: '90px',
      hideSortIcon: true,
      hideDelete: true,
      hideEdit: true,
      viewPath: `${crudConfig.modulePath}/view`,
    },
  ]
}
