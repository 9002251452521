import _ from 'lodash'
// import { callAPI } from '../../../../../../../utils/lib'
// import { stationFilter } from '../../../../../../../utils/apiPath'
import { setFieldValue } from '../../../../../../../redux/slices/crud'
import { toggleFiltersDrawer } from '../../handler/handleClickOpenDrawer'
import dayjs from 'dayjs'
import { filtersDrawerEnum } from '../../enums/drawerEnum'
import { fetchOverview } from '../../handler/fetchOverview'
import { defaultFilter } from './model'
import { store } from '../../../../../../../App'

export const handleFiltering = (filter) => (dispatch) => {
  let countFilterTotal = 0
  const isFilterDate = filter.dateChecked && filter.startDate && filter.endDate
  if (isFilterDate) ++countFilterTotal
  const isFilterCourseName = filter.courseNameChecked && filter.courseName
  if (isFilterCourseName) ++countFilterTotal
  const isFilterTrainingDate = filter.trainingDateChecked && filter.trainingDate
  if (isFilterTrainingDate) ++countFilterTotal
  const isFilterTrainerName = filter.trainerNameChecked && filter.trainerName
  if (isFilterTrainerName) ++countFilterTotal
  const isFilterDistribution = filter.distributionChecked && filter.distribution
  if (isFilterDistribution) ++countFilterTotal
  dispatch(
    setFieldValue({
      key: 'eEvaluationDashboard.filtersDrawer',
      value: {
        ...filtersDrawerEnum,
        startDate: isFilterDate
          ? dayjs(filter.startDate).format('YYYY-MM-DD')
          : dayjs().startOf('month').format('YYYY-MM-DD'),
        endDate: isFilterDate
          ? dayjs(filter.endDate).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD'),
        courseName: isFilterCourseName ? filter.courseName : '',
        trainingDate: isFilterTrainingDate ? filter.trainingDate : '',
        trainerName: isFilterTrainerName ? filter.trainerName : '',
        distribution: isFilterDistribution ? filter.distribution : '',
        filterTotal: countFilterTotal,
        isFilterDate: isFilterDate,
        isFilterCourseName,
        isFilterTrainingDate,
        isFilterTrainerName,
        isFilterDistribution,
      },
    }),
  )
  toggleFiltersDrawer(false)
  fetchOverview()
}

export const handleChange = ({
  key,
  value,
  filter,
  setFilter,
  listKey,
  isCheck,
}) => {
  let objState = {}
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  } else if (isCheck) {
    const subKey = key.replace('Checked', '')
    objState = {
      ...filter,
      [key]: value,
      [subKey]: defaultFilter[subKey],
    }
  } else {
    objState = {
      ...filter,
      [key]: value,
    }
  }
  setFilter(objState)
}

export const fetchFilterOptions = async () => {
  // const res = await store.dispatch(getELearningList())
  // const data = _.get(res, 'payload.data', [])
  const courseName = [{ label: 'test 1', value: 1 }]
  // const platformLearningList = _.defaultTo(data, []).map((item) => ({
  //   label: `${item.name}`,
  //   name: _.lowerCase(item.name).replace(' ', '') + 'Checked',
  //   uuid: item.uuid,
  //   value: item.uuid,
  //   courseCode: item.courseCode,
  // }))
  store.dispatch(
    setFieldValue({
      key: 'filterList.courseName',
      value: courseName,
    }),
  )

  const trainingDate = [{ label: 'test 1', value: 1 }]
  store.dispatch(
    setFieldValue({
      key: 'filterList.trainingDate',
      value: trainingDate,
    }),
  )
  const trainerName = [{ label: 'test 1', value: 1 }]
  store.dispatch(
    setFieldValue({
      key: 'filterList.trainerName',
      value: trainerName,
    }),
  )
  const distribution = [{ label: 'test 1', value: 1 }]
  store.dispatch(
    setFieldValue({
      key: 'filterList.distribution',
      value: distribution,
    }),
  )
}

export const handleSelectDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    endDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  setFilter(objState)
}

export const handleSelectMultiple = (list, filter, setFilter, nameInput) => {
  let objState = {
    ...filter,
    [`${nameInput}List`]: list,
  }
  setFilter(objState)
}

export const clearFilter = (key, filter, setFilter, setFilterMemo) => {
  let objState = {
    ...filter,
  }
  if (key === 'date') {
    objState = {
      ...objState,
      dateChecked: false,
      startDate: '',
      endDate: '',
      displayDate: false,
    }
  } else if (key === 'station') {
    objState = {
      ...objState,
      stationChecked: false,
      station: undefined,
    }
  }
  setFilter(objState)
  setFilterMemo(objState)
}
