import { Box, Button, Card, CardContent } from '@mui/material'
import { ToggleContent } from '../ToggleContent'
import { axisLabel, chartPart1Model, partTitle } from '../../models/partModel'
import { courseManagePartModel } from '../../models/overview'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { DataCard } from '../ModuleCard/DataCard'
import BarChart from '../../../../../../../components/Chart/BarChart'
import { handleClickViewButton } from '../../handler/handleClickViewButton'
import { questionTypeText } from '../../constants/type'

const CourseManagementEva = () => {
  const courseManageEvaPart = courseManagePartModel()
  const chart = chartPart1Model().map((item) => item.number) // [] data
  const labels = chartPart1Model().map((item) => item.label)
  const bgColors = chartPart1Model().map((item) => item.backgroundColor)
  return (
    <Box mb={3}>
      <Card>
        <CardContent>
          <ToggleContent
            title={partTitle.part3}
            type={questionTypeText.RATING}
            contentId="courseManagementEva"
          >
            <Box>
              <Button
                data-testid="btn-preview"
                variant="outlined"
                size="m"
                endIcon={<ChevronRightIcon color="primary" />}
                onClick={() => {
                  handleClickViewButton('part3')
                }}
              >
                ดูรายละเอียดคำตอบ
              </Button>
            </Box>
            <Box
              display="flex"
              flexDirection={'column'}
              gap={2}
              alignItems={'start'}
              width={'100%'}
            >
              <DataCard
                dataList={courseManageEvaPart}
                totalModule={''}
                border={'none'}
                padding={0}
              />
            </Box>
            <Box minHeight="250px">
              <BarChart
                dataSet={{
                  data: chart,
                  backgroundColor: bgColors,
                }}
                labels={labels}
                height={'300px'}
                showXAxisLabel={true}
                showYAxisLabel={true}
                xAxisLabel={axisLabel.x}
                yAxisLabel={axisLabel.y}
                showPercent={true}
                eleId={`part3-chart`}
              />
            </Box>
          </ToggleContent>
        </CardContent>
      </Card>
    </Box>
  )
}

export default CourseManagementEva
