export const axisLabel = {
  x: 'ลำดับ',
  y: ' จำนวนคำตอบ',
}

export const chartCount = [
  { label: 'ดีมาก', number: 50, backgroundColor: '#B2DFDB' },
  { label: 'ดี', number: 40, backgroundColor: '#C8E6C9' },
  { label: 'ปานกลาง', number: 40, backgroundColor: '#DCEDC8' },
  { label: 'พอใจ', number: 40, backgroundColor: '#F0F4C3' },
  { label: 'ควรปรับปรุง', number: 30, backgroundColor: '#FFF9C4' },
]

export const chartPart1Model = () => {
  return [...chartCount]
}

export const partTitle = {
  part1: 'ตอนที่ 1 แบบประเมิณหลักสูตร',
  part2: 'ตอนที่ 2 แบบประเมิณวิทยากร',
  part3: 'ตอนที่ 3 แบบประเมินบริหารจัดการหลักสูตร',
  part4: 'ตอนที่ 4 แบบประเมิณ / แบบสอบถามอื่นๆ',
}
