export const filterItems = [
  {
    label: `วันที่`,
    nameCheckBox: 'testDateChecked',
    nameInput: 'testDate',
    placeholder: `เลือกช่วงเวลา`,
    dateStateStart: 'testDateStart',
    dateStateFinish: 'testDateFinish',
    dateStateDisplay: 'testDateDisplayChecked',
    type: 'daterange',
  },
  {
    label: `ผู้ทำแบบทดสอบ`,
    nameCheckBox: 'testerChecked',
    nameInput: 'tester',
    placeholder: `เลือก tester`,
    type: 'select_dropdown',
  },
  {
    label: `คลาส / รอบอบรม`,
    nameCheckBox: 'classChecked',
    nameInput: 'classCategory',
    placeholder: `เลือก คลาส / รอบอบรม`,
    type: 'select_dropdown',
  },
  {
    label: `หลักสูตร`,
    nameCheckBox: 'coursesChecked',
    nameInput: 'courses',
    placeholder: `เลือกหลักสูตร`,
    type: 'select_dropdown',
  },
  {
    label: `ช่องทางการจัดจำหน่าย`,
    nameCheckBox: 'distributionChecked',
    nameInput: 'distribution',
    placeholder: `กรุณาใส่ Tag`,
    type: 'select_dropdown',
  },
  {
    label: `SRAM`,
    nameCheckBox: 'SRAMChecked',
    nameInput: 'SRAM',
    placeholder: `เลือก SRAM`,
    type: 'select_dropdown',
  },
  {
    label: `RAM`,
    nameCheckBox: 'RAMChecked',
    nameInput: 'RAM',
    placeholder: `เลือก RAM`,
    type: 'select_dropdown',
  },
  {
    label: `Trainer`,
    nameCheckBox: 'trainerChecked',
    nameInput: 'trainer',
    placeholder: `เลือก Trainer`,
    type: 'select_dropdown',
  },
]
