import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import _ from 'lodash'
import Breadcrumbs from '../../../../../components/Breadcrumbs'
import { StyledViewTitle } from '../../../../ETesting/Version/View/Styled'
import { StyledContainer, StyledContent } from '../../../Styled'
import { breadcrumbVersionViewDashboard } from '../../../model'
import { fetchEEvaluation } from './events'
import LeftInfo from './components/LeftInfo'
import { initialize } from './handler/initilize'
import { toggleFiltersDrawer } from './handler/handleClickOpenDrawer'
import { Button, Typography } from '@mui/material'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import FilterDrawer from './Drawers/FilterDrawer'
import CourseEva from './components/CourseEva'
import TrainerEva from './components/TrainerEva'
import CourseManagementEva from './components/CourseManagementEva'
import OtherEva from './components/OtherEva'
import EvaluationDrawer from './Drawers/EvaluationDrawer'

const View = () => {
  const { id: uuid } = useParams()
  const { result, isLoading } = useSelector((state) => state.eEvaluationDetail)
  const { filterTotal } = useSelector(
    (state) => ({
      filterTotal: state.crud.eEvaluationDashboard?.filtersDrawer?.filterTotal,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchEEvaluation(uuid))
    initialize()
  }, [])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <Box>
          <StyledViewTitle variant="h4">
            รายละเอียดแบบประเมิน : {_.get(result, 'information.name', '')}
          </StyledViewTitle>
        </Box>
        <Breadcrumbs
          menuList={breadcrumbVersionViewDashboard(_.get(result, 'codeId', 0))}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          mt={3}
        >
          <LeftInfo uuid={uuid} />
          <Box>
            <Button
              data-testid={'btn-filter'}
              sx={{ height: 40, py: 1, px: 2 }}
              variant="text"
              startIcon={<FilterListOutlinedIcon />}
              onClick={() => toggleFiltersDrawer(true)}
            >
              <Typography variant="text">
                ตัวกรอง {filterTotal ? `(${filterTotal})` : ''}
              </Typography>
            </Button>
          </Box>
        </Box>
        <Box mt={3}>
          <CourseEva />
          <TrainerEva />
          <CourseManagementEva />
          <OtherEva />
        </Box>
      </StyledContent>
      <FilterDrawer />
      <EvaluationDrawer />
    </StyledContainer>
  )
}

export default View
