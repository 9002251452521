import { store } from '../../../../../../../App'
import _ from 'lodash'
import dayjs from 'dayjs'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter

  return {
    distribution: _.get(filterProp, 'distribution', ''),
    agentCode: _.get(filterProp, 'agentCode', ''),
    unitCode: _.get(filterProp, 'unitCode', ''),
    RAM: _.get(filterProp, 'RAM', ''),
    SRAM: _.get(filterProp, 'SRAM', ''),
    scoreFrom: _.get(filterProp, 'scoreFrom', ''),
    scoreTo: _.get(filterProp, 'scoreTo', ''),
    testDateStart: _.isNil(filterProp?.testDateStart)
      ? undefined
      : dayjs(filterProp.testDateStart).utc(true).toDate(),
    testDateFinish: _.isNil(filterProp?.testDateFinish)
      ? undefined
      : dayjs(filterProp.testDateFinish).utc(true).toDate(),
    status: _.get(filterProp, 'status', []),
  }
}
