import React from 'react'
import _ from 'lodash'
import useLocalStorage from '@rehooks/local-storage'

import Typography from '@mui/material/Typography'
import {
  convertFormatDateTime,
  formatPhoneNumber,
  formatIdCard,
  getDateThai,
  validatePermission,
} from '../../../../../utils/lib'
import { StyledCard } from '../../../Styled'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Dot from '../../../../../components/Table/Dot'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { useDispatch } from 'react-redux'

import {
  StyledCardContent,
  StyledContentRow,
  StyledFooterText,
  ProfileImg,
} from './Styled'

import { handleAnnounceLearner } from './events'
import { handleDeleteDialog } from '../../../../../components/redux/Table/Action/ActionBatchLearner/events'
import { getCourseFormState } from '../../Form/events'

const DetailTab = ({ detail, enrollData }) => {
  const eLearningIdCardEnroll = _.get(enrollData, 'eLearningIdCardEnroll', {})
  const prefixTH = _.get(detail, 'userProfile.prefixTH', '')
  const fullName =
    _.get(detail, 'userProfile.firstNameTH', '') +
    ' ' +
    _.get(detail, 'userProfile.lastNameTH', '')
  const idCardNo = formatIdCard(_.get(detail, 'userProfile.idCardNo', ''))

  const mobile = _.get(detail, 'userProfile.mobileNo', '')
  const mobileNo = !mobile
    ? '-'
    : formatPhoneNumber(_.get(detail, 'userProfile.mobileNo', ''))

  const email = _.get(detail, 'userProfile.email', '')
  const statusTH = _.get(detail, 'statusTH', '')
  const status = statusTH === 'รอประกาศสิทธิ์' ? 'WAITING' : 'ACTIVE'

  const dateOfBirth = _.get(detail, 'userProfile.dateOfBirth', '')
  const birthDate = !dateOfBirth ? '' : getDateThai(dateOfBirth)

  const codeId = _.get(detail, 'codeId', '')
  const educationals = _.get(detail, 'userProfile.educational', [])

  let educational = ''
  for (const data of educationals) {
    if (data.education) {
      if (educational === '') {
        educational += data.education
      } else {
        educational += ', ' + data.education
      }
    }
  }

  const courseBatchLearnerUuid = _.get(detail, 'uuid', '')
  const courseBatchUuid = _.get(detail, 'eLearningCourseBatch.uuid', '')
  const theme = useTheme()
  const image = _.get(detail, 'userProfile.image', '')

  const userType = _.get(detail, 'userProfile.userType', '')
  const fullAgentCode = _.get(
    detail,
    'userProfile.agentProfile.fullAgentCode',
    '',
  )

  return (
    <StyledCard sx={{ mt: 0 }}>
      <StyledCardContent>
        <ContainerBox
          sx={{
            gap: 3,
            py: 3,
            px: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flex: '1 0 0',
            }}
          >
            <ContainerBox sx={{ position: 'relative' }}>
              <Typography sx={{ pb: 1.5, wordWrap: 'break-word' }} variant="h6">
                {'ข้อมูลส่วนตัว'}
              </Typography>
              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: '50px',
                  borderRadius: '50%',
                  [theme.breakpoints.down('sm')]: {
                    display: 'block',
                    pointerEvents: image ? 'unset' : 'none',
                    alignSelf: 'center',
                  },
                }}
              >
                <ProfileImg
                  url={
                    image
                      ? `${window.__env__.REACT_APP_API_URL}/file${image}`
                      : '/logo/staff_profile_full.png'
                  }
                />
              </Box>
              <ContentField
                field="คำนำหน้าชื่อ"
                content={prefixTH}
                limitWidth={'400px'}
                isInfo
              />

              <ContentField
                field="ชื่อ - นามสกุล"
                content={fullName}
                limitWidth={'400px'}
                isInfo
              />
              <ContentField
                field="เลขบัตรประชาชน"
                content={idCardNo || '-'}
                limitWidth={'400px'}
                isInfo
              />

              <ContentField
                field="วัน เดือน ปี เกิด"
                content={birthDate || '-'}
                limitWidth={'400px'}
                isInfo
              />
              <ContentField
                field="เบอร์โทรศัพท์"
                content={mobileNo || '-'}
                limitWidth={'400px'}
                isInfo
              />
              <ContentField
                field="อีเมล"
                content={email}
                limitWidth={'400px'}
                isInfo
              />
              <ContentField
                field="สถานะ"
                content={statusTH}
                limitWidth={'400px'}
                isInfo
                status={status}
                courseBatchLearnerUuid={courseBatchLearnerUuid}
                courseBatchUuid={courseBatchUuid}
              />

              <Typography sx={{ pb: 1.5, wordWrap: 'break-word' }} variant="h6">
                {'ข้อมูลการทำงาน'}
              </Typography>
              {userType === 'AGENT' && (
                <>
                  <ContentField
                    field="สังกัดหน่วยงาน"
                    content={_.get(
                      detail,
                      'userProfile.agentProfile.agentUnitCode',
                      '-',
                    )}
                    limitWidth={'400px'}
                    isInfo
                  />

                  <ContentField
                    field="รหัส Agent แบบเต็ม"
                    content={fullAgentCode || '-'}
                    limitWidth={'400px'}
                    isInfo
                  />
                  <ContentField
                    field="รหัส Agent"
                    content={codeId || '-'}
                    limitWidth={'400px'}
                    isInfo
                  />
                </>
              )}
              {userType === 'STAFF' && (
                <>
                  <ContentField
                    field="รหัส Agent"
                    content={codeId || '-'}
                    limitWidth={'400px'}
                    isInfo
                  />
                </>
              )}
              {userType === 'PROSPECT' && (
                <>
                  <ContentField
                    field="สังกัดหน่วยงาน"
                    content={_.get(
                      detail,
                      'userProfile.prospectProfile.managerUser.agentProfile.agentUnitCode',
                      '-',
                    )}
                    limitWidth={'400px'}
                    isInfo
                  />
                  <ContentField
                    field="รหัส Agent"
                    content={codeId || '-'}
                    limitWidth={'400px'}
                    isInfo
                  />
                </>
              )}

              <Typography sx={{ pb: 1.5, wordWrap: 'break-word' }} variant="h6">
                {'ประสบการณ์'}
              </Typography>

              <ContentField
                field="วุฒิการศึกษา"
                content={educational || '-'}
                limitWidth={'400px'}
                isInfo
              />

              {!_.isEmpty(eLearningIdCardEnroll) && (
                <>
                  <Typography
                    sx={{ pb: 1.5, wordWrap: 'break-word' }}
                    variant="h6"
                  >
                    {'เอกสาร'}
                  </Typography>

                  <ContentField
                    field="บัตรประชาชน"
                    content={_.get(eLearningIdCardEnroll, 'idCardImageKey', '-').split('/').pop()}
                    limitWidth={'400px'}
                    isInfo
                  />
                </>
              )}
            </ContainerBox>
          </Box>
        </ContainerBox>
        {/* <StyledDivider /> */}
        {/* <FooterContent title="สร้าง" field="created" detail={detail} />
        <FooterContent title="แก้ไขล่าสุด" field="updated" detail={detail} /> */}
      </StyledCardContent>
    </StyledCard>
  )
}

export default DetailTab

export const ContentRow = ({ title, content }) => {
  return (
    <StyledContentRow>
      <Typography id="label" variant="body1b">
        {title}
      </Typography>
      <Typography sx={{ wordBreak: 'break-all' }} variant="body1">
        {content}
      </Typography>
    </StyledContentRow>
  )
}

const ContainerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    '& > .MuiBox-root:last-child': {
      paddingBottom: 0,
    },
  },
}))

export const ContentField = (props) => {
  const theme = useTheme()
  const {
    field,
    content,
    isInfo,
    limitWidth,
    index,
    isExcepted,
    status,
    courseBatchLearnerUuid,
    courseBatchUuid,
  } = props
  const dispatch = useDispatch()
  const [user] = useLocalStorage('user')
  const data = getCourseFormState()
  const hasApprove = validatePermission({
    user: user,
    moduleType: 'E_LEARNING',
    permission: ['HEAD_ADMIN', 'ADMIN'],
    data,
  })
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        pb: 1.5,
        [theme.breakpoints.down('sm')]: {
          flexDirection: isExcepted ? 'row' : 'column',
          pb: isExcepted ? 1.5 : 2,
        },
      }}
    >
      <Typography
        color="#5F5F5F"
        sx={{
          alignSelf: field === 'ผู้รับผิดชอบ' ? 'start' : 'center',
          width: isInfo ? '40%' : '30%',
          textIndent: 20,
          [theme.breakpoints.down('sm')]: {
            fontWeight: 'bold',
            textIndent: 0,
            width: '100%',
            alignSelf: 'start',
            flex:
              field === 'ผู้จัดทำ' || field === 'ผู้รับผิดชอบ' ? 35 : 'unset',
          },
        }}
      >
        {index > 1 ? '' : field}
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Typography
          sx={{
            alignSelf: 'center',
            width: isInfo ? '60%' : '70%',
            maxWidth: limitWidth ? limitWidth : 'unset',
            wordWrap: 'break-word',
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
              textIndent: 0,
              width: '100%',
              alignSelf: 'start',
              flex:
                field === 'ผู้จัดทำ' || field === 'ผู้รับผิดชอบ' ? 65 : 'unset',
            },
          }}
        >
          {' '}
          {field === 'สถานะ' && <Dot sx={{ mr: 1 }} status={status} />}
          {content}
          {hasApprove && status === 'WAITING' && (
            <>
              <Button
                sx={{
                  ml: 2,
                  color: 'text.white',
                  gap: 1,
                  width: '94px',
                  fontSize: '18px',
                }}
                variant="contained"
                size="s"
                color="success"
                onClick={() =>
                  dispatch(
                    handleAnnounceLearner(
                      courseBatchUuid,
                      courseBatchLearnerUuid,
                    ),
                  )
                }
              >
                <CheckIcon x={{ fontSize: '18px' }} />
                {'อนุมัติ'}
              </Button>
              <Button
                sx={{ ml: 2, gap: 1, fontSize: '18px' }}
                variant="outlined"
                size="s"
                color="error"
                onClick={() =>
                  dispatch(handleDeleteDialog(courseBatchLearnerUuid))
                }
              >
                <ClearIcon sx={{ fontSize: '18px' }} />
                {'ไม่อนุมัติ'}
              </Button>
            </>
          )}
        </Typography>
      </Box>
    </Box>
  )
}

export const FooterContent = ({ title, detail, field }) => {
  const name = _.get(detail, `${field}By.name`, '')
  const dateTime = convertFormatDateTime({
    value: _.get(detail, `${field}At`, ''),
    type: 'dateTime',
  })

  return (
    <StyledFooterText variant="caption" color="text.lightGray">
      {title}โดย {name} {dateTime}
    </StyledFooterText>
  )
}
