import { Button, Typography } from '@mui/material'
import _ from 'lodash'

import {
  closeDialog,
  openDialog,
} from '../../../../../../../redux/slices/dialog'
import { store } from '../../../../../../../App'

const ViewString = ({ value = '', limit = 200, customSx = {} }) => {
  const renderValue = () => {
    if (_.isEmpty(value)) {
      return '-'
    }
    if (value.length <= limit) {
      return value
    }
    return `${value.substring(0, limit)}...`
  }

  return (
    <Typography
      variant="body2"
      sx={{
        lineBreak: 'anywhere',
        letterSpacing: '0.15px',
        WebkitLineClamp: '2',
        textOverflow: 'ellipsis',
        height: '4em',
        ...customSx,
      }}
    >
      {renderValue()}
      {value.length > limit && (
        <Button
          color="primary"
          variant="text"
          sx={{ ml: 1 }}
          onClick={() => {
            store.dispatch(
              openDialog({
                type: 'content',
                title: 'คำถาม',
                content: (
                  <Typography
                    sx={{
                      width: '100%',
                      padding: '16px',
                      lineBreak: 'anywhere',
                    }}
                  >
                    {value}
                  </Typography>
                ),
                agreeText: 'ตกลง',
                hideCancel: true,
                isCloseDialog: false,
                handleConfirm: () => {
                  store.dispatch(closeDialog())
                },
              }),
            )
          }}
        >
          ดูเพิ่มเติม
        </Button>
      )}
    </Typography>
  )
}

export default ViewString
