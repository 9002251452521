import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { contentType } from '../enum/enum'
import { Row, Column } from 'src/components/Layout/Styled'
import { StyledNumber, StyledText } from '../Styled'

const PinnedCard = ({ type }) => {
  const { pinData } = useSelector(
    (state) => ({
      pinData: state.crud?.eContentDashboard?.overview?.pinnedData?.[type],
    }),
    shallowEqual,
  )
  return (
    <Box
      sx={{
        padding: '4px 12px 12px 12px',
        borderRadius: '8px',
        border: '1px solid #DBE4F199',
      }}
    >
      <Typography variant="body2" color="text.secondary">
        {contentType[type].label}
      </Typography>
      <Grid container mt={1}>
        <Grid item xs={6}>
          <Row sx={{ mb: 1 }}>
            <Typography
              variant="body3"
              color="text.lightGray"
              sx={{ width: 24, ml: 0.5, mr: 0.75, textAlign: 'center' }}
            >
              #
            </Typography>
            <Typography variant="body3" color="text.lightGray">
              ชื่อเรื่อง
            </Typography>
          </Row>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body3" color="text.lightGray">
            วันที่เผยแพร่
          </Typography>
        </Grid>
      </Grid>
      <Column sx={{ gap: 1 }}>
        {pinData?.length > 0 ? (
          pinData.map((ele, ind) => {
            return (
              <Grid
                container
                key={ind}
                sx={{
                  p: 0.5,
                  pb: 0,
                  borderRadius: '16px',
                  background: '#F1F4FA',
                }}
              >
                <Grid item xs={6}>
                  <Row>
                    <StyledNumber variant="caption" color="text.white">
                      {ind + 1}
                    </StyledNumber>
                    <StyledText variant="body2b" color="primary.main">
                      {ele.name}
                    </StyledText>
                  </Row>
                </Grid>
                <Grid item xs={6}>
                  <StyledText variant="body2b" color="primary.main">
                    {dayjs(ele.startDate).format('DD/MM/YYYY')}
                    {ele.endDate &&
                      `-${dayjs(ele.endDate).format('DD/MM/YYYY')}`}
                  </StyledText>
                </Grid>
              </Grid>
            )
          })
        ) : (
          <Typography
            variant="body2"
            color="text.lightGray"
            sx={{ margin: '98px', textAlign: 'center' }}
          >
            ไม่พบข้อมูล
          </Typography>
        )}
      </Column>
    </Box>
  )
}

export default PinnedCard
