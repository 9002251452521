// import { validatePermission } from '../../../../../utils/lib'
import { crudConfig } from '../../crudConfig'

export const headerCells = () => {
  //const user = JSON.parse(localStorage.getItem('user'))
  // const hasPermissionDelete = validatePermission({
  //   user: user,
  //   moduleType: 'MASTER_DATA',
  //   permission: ['DELETE'],
  // })

  return [
    {
      id: 'id',
      label: 'ID',
      width: '30px',
      disablePadding: false,
    },
    {
      id: 'testName',
      label: 'แบบทดสอบ',
      disablePadding: false,
    },
    {
      id: 'countVersion',
      label: 'จำนวนเวอร์ชัน',
      disablePadding: false,
    },
    {
      id: 'checkStatus',
      label: 'รอตรวจ',
      disablePadding: false,
    },

    {
      id: 'action',
      disablePadding: false,
      label: 'คำสั่ง',
      width: '90px',
      hideSortIcon: true,
      hideDelete: true,
      hideEdit: true,
      viewPath: `${crudConfig.modulePath}/view`,
    },
  ]
}
