import { store } from '../../../../../../../App'

export const mutateFilterSateToProp = () => {
  const { filterState } = store.getState().table
  let status = []

  return {
    course: filterState.courseChecked ? filterState.course : '',
    class: filterState.classChecked ? filterState.class : '',
    distribution: filterState.distributionChecked
      ? filterState.distribution
      : '',
    agentCode: filterState.agentCodeChecked ? filterState.agentCode : '',
    testDateStart: filterState.testDateChecked ? filterState.testDateStart : '',
    testDateFinish: filterState.testDateChecked
      ? filterState.testDateFinish
      : '',
    unitCode: filterState.unitCode ? filterState.unitCode : '',
    SRAM: filterState.SRAM ? filterState.SRAM : '',
    RAM: filterState.RAM ? filterState.RAM : '',
    Trainer: filterState.Trainer ? filterState.Trainer : '',
    status: status,
  }
}
