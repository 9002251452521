import { downloadFile } from '../../../services/util'
import { store } from '../../../App'
import { startLoading, stopLoading } from '../../../redux/slices/reportComp'
import { reportDownload } from '../../../utils/apiPath'
import { handleFilterMixModule } from './handleFilterMixModule'

export const onDownload = async () => {
  const {
    columns,
    hasSortedColumns,
    bodyFilters,
    reportConfig: { modulePath, downloadName, moduleId },
  } = store.getState().reportComp

  if (moduleId === 'REPORT_MIX_MODULE') {
    const res = await store.dispatch(handleFilterMixModule(true))
    if (!res) return
  }

  store.dispatch(startLoading())

  const body = {
    columns,
    filters: bodyFilters,
    hasSortedColumns,
    moduleId,
  }
  await store.dispatch(
    downloadFile({
      url: `${reportDownload}${modulePath}`,
      body: body,
      fileName: downloadName,
    }),
  )
  store.dispatch(stopLoading())
}
