import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getFormatDate } from '../../../../../utils/lib'
import { BoxWrapper, FilterStyledBox, StyledClearIcon } from './Styled'
import { handleClearFilter } from '../handler/handleClearFilter'

export const FilterBox = () => {
  const { eTestingDashboard } = useSelector(
    (state) => ({
      eTestingDashboard: state.crud.eTestingDashboard,
    }),
    shallowEqual,
  )
  const TesterFilter = eTestingDashboard?.filters?.Tester ?? []
  const startDateFilter = eTestingDashboard?.filters?.startDate ?? ''
  const endDateFilter = eTestingDashboard?.filters?.endDate ?? ''
  const hasFilter =
    TesterFilter?.length > 0 || (startDateFilter && endDateFilter)
  const isFilterTester = eTestingDashboard?.filters?.isFilterTester ?? ''
  const isFilterDate = eTestingDashboard?.filters?.isFilterDate ?? ''
  return (
    <>
      {hasFilter ? (
        <BoxWrapper>
          {startDateFilter && endDateFilter && (
            <FilterStyledBox>
              วันที่:{' '}
              <strong>
                {getFormatDate(startDateFilter)} -{' '}
                {getFormatDate(endDateFilter)}
              </strong>
              {isFilterDate && (
                <StyledClearIcon
                  onClick={() => handleClearFilter('filterDate')}
                />
              )}
            </FilterStyledBox>
          )}

          {TesterFilter?.length > 0 && (
            <FilterStyledBox>
              ผู้ทำแบบทดสอบ:{' '}
              <strong>
                {TesterFilter.map((item) => item.label).join(', ')}
              </strong>
              {isFilterTester && (
                <StyledClearIcon
                  onClick={() => handleClearFilter('filterTester')}
                />
              )}
            </FilterStyledBox>
          )}
        </BoxWrapper>
      ) : (
        ''
      )}
    </>
  )
}
