import _ from 'lodash'

export const breadcrumbList = [
  { title: 'E-Evaluation', link: '/', pointer: false },
]

export const breadcrumbVersion = [
  { title: 'E-Evaluation', link: '/e-evaluation', pointer: true },
  { title: 'เวอร์ชั่น', link: '/', pointer: false },
]

export const breadcrumbVersionView = (codeId) => {
  return [
    { title: 'E-Evaluation', link: '/e-evaluation', pointer: true },
    {
      title: 'เวอร์ชั่น',
      link: '/e-evaluation/version/' + codeId,
      pointer: true,
    },
    {
      title: 'รายละเอียดแบบประเมิน',
      link: '/e-evaluation/version',
      pointer: false,
    },
  ]
}

export const breadcrumbForm = (type) => {
  return [
    { title: 'E-Evaluation', link: '/e-evaluation', pointer: true },
    {
      title: `สร้างแบบประเมิน (${_.capitalize(type) || ''})`,
      link: '/',
      pointer: false,
    },
  ]
}

export const breadcrumbFormEdit = (codeId, eEvaluationType, titleLabelEva) => {
  return [
    { title: 'E-Evaluation', link: '/e-evaluation', pointer: true },
    {
      title: 'เวอร์ชั่น',
      link: '/e-evaluation/version/' + codeId,
      pointer: true,
    },
    {
      title: `${titleLabelEva} (${
        eEvaluationType === 'CLASS' ? 'Class' : 'Standalone'
      })`,
      link: '/',
      pointer: false,
    },
  ]
}

export const breadcrumbListDashboard = [
  { title: 'Dashboard', link: '/', pointer: false },
]

export const breadcrumbVersionDashboard = [
  { title: 'Dashboard', link: '/e-evaluation/dashboard', pointer: true },
  { title: 'เวอร์ชั่น', link: '/', pointer: false },
]

export const breadcrumbVersionViewDashboard = (codeId) => {
  return [
    { title: 'E-Evaluation', link: '/e-evaluation/dashboard', pointer: true },
    {
      title: 'Dashboard',
      link: '/e-evaluation/dashboard/version/' + codeId,
      pointer: true,
    },
    {
      title: 'รายละเอียดแบบประเมิน',
      link: '/e-evaluation/dashboard/version',
      pointer: false,
    },
  ]
}