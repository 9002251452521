export const headCells = [
  {
    id: 'no',
    label: 'ลำดับ',
    width: '40px',
  },
  {
    id: 'count',
    label: 'จำนวน',
    width: '40px',
  },
  {
    id: 'percent',
    label: '%',
    width: '40px',
  },
  {
    id: 'answer',
    label: 'คำตอบ',
    isTruncatedDialog: true,
    readMoreTxt: 'ดูเพิ่มเติม',
    maxWidth: '800px',
  },
]
