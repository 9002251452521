import { setReduxValue } from '../../../redux/slices/reportComp'
import { handleSaveFilters } from './handleSaveFilters'
import { getQueryParams } from '../../../utils/lib'
import dayjs from 'dayjs'

export const fetchSerachParamsProspectProfile = () => async (dispatch) => {
  let startDate = getQueryParams('start_date')
  let endDate = getQueryParams('end_date')
  let status = getQueryParams('status')
  let statusProspect = getQueryParams('status_prospect')
  const filter = []
  let id = 1
  if (startDate && endDate) {
    filter.push({
      id: id,
      key: 'lastLogin',
      label: `${dayjs(startDate).format('DD/MM/YYYY')} - ${dayjs(
        endDate,
      ).format('DD/MM/YYYY')}`,
      title: 'เข้าสู่ระบบล่าสุด',
      typeOfValue: 'DATE_RANGE',
      value: { startDate, endDate },
    })
    id++
  }
  if (status) {
    status = status.toUpperCase()
    filter.push({
      id: id,
      key: 'status',
      label: status.replace('INACTIVE', 'Terminate'),
      title: 'สถานะบัญชี',
      typeOfValue: 'INPUT_TEXT',
      value: status,
    })
    id++
  }
  if (statusProspect) {
    statusProspect =
      statusProspect == 'true' ? 'เป็นตัวแทนแล้ว' : 'ยังไม่เป็นตัวแทน'
    filter.push({
      id: id,
      key: 'statusProspect',
      label: statusProspect,
      title: 'สถานะตัวแทน',
      typeOfValue: 'INPUT_TEXT',
      value: statusProspect,
    })
    id++
  }

  if (filter.length > 0) {
    await dispatch(
      setReduxValue({
        key: 'displayFilters',
        value: filter,
      }),
    )
    dispatch(handleSaveFilters())
  }
}
