import { store } from '../../../../../App'
import dayjs from 'dayjs'
import { setFieldValue } from '../../../../../redux/slices/crud'
import _ from 'lodash'

export const handleClearFilter = (key) => {
  const { filters } = store.getState().crud.eTestingDashboard
  let countFilterTotal = 0
  if (filters?.isFilterDate) ++countFilterTotal
  if (filters?.isFilterTester) ++countFilterTotal
  if (key === 'filterDate') {
    store.dispatch(
      setFieldValue({
        key: 'eTestingDashboard.filters.startDate',
        value: dayjs().startOf('month').format('YYYY-MM-DD'),
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eTestingDashboard.filters.endDate',
        value: dayjs().format('YYYY-MM-DD'),
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eTestingDashboard.filters.isFilterDate',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eTestingDashboard.filters.isClearFilter',
        value: 'date',
      }),
    )
  } else if (key === 'filterTester') {
    const user = JSON.parse(localStorage.getItem('user'))
    const userTester = _.get(user, 'name', '')
    store.dispatch(
      setFieldValue({
        key: 'eTestingDashboard.filters.tester',
        value: [
          {
            label: userTester,
            name: `${userTester.toLowerCase()}Checked`,
            uuid: user.uuid,
          },
        ],
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eTestingDashboard.filters.isFilterTester',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eTestingDashboard.filters.isClearFilter',
        value: 'tester',
      }),
    )
  }
  store.dispatch(
    setFieldValue({
      key: 'eTestingDashboar.filters.filterTotal',
      value: countFilterTotal - 1,
    }),
  )
}
