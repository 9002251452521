export const filterStatusItems = [
  {
    label: 'ผ่าน',
    name: 'passChecked',
  },
  {
    label: 'ไม่ผ่าน',
    name: 'failChecked',
  },
]

export const filterItems = [
  {
    label: `วันที่`,
    nameCheckBox: 'testDateChecked',
    nameInput: 'testDate',
    placeholder: `เลือกช่วงเวลา`,
    dateStateStart: 'testDateStart',
    dateStateFinish: 'testDateFinish',
    dateStateDisplay: 'testDateDisplayChecked',
    type: 'daterange',
  },
  {
    label: `Agent Code`,
    nameCheckBox: 'agentCodeChecked',
    nameInput: 'agentCode',
    placeholder: `เลือก Agent code`,
    type: 'select_dropdown',
  },
  {
    label: `Unit Code`,
    nameCheckBox: 'unitCodeChecked',
    nameInput: 'unitCode',
    placeholder: `เลือก  Unit Code`,
    type: 'select_dropdown',
  },
  {
    label: `SRAM`,
    nameCheckBox: 'SRAMChecked',
    nameInput: 'SRAM',
    placeholder: `เลือก SRAM`,
    type: 'select_dropdown',
  },
  {
    label: `RAM`,
    nameCheckBox: 'RAMChecked',
    nameInput: 'RAM',
    placeholder: `เลือก RAM`,
    type: 'select_dropdown',
  },
  {
    label: 'ผลการสอบ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: filterStatusItems,
  },
  {
    label: `ช่วงคะแนน`,
    nameCheckBox: 'scoreChecked',
    nameInput: 'score',
    placeholder: `ระบุจำนวน`,
    inputFrom: 'scoreFrom',
    inputTo: 'scoreTo',
    type: 'number_between',
  },
  {
    label: `ช่องทางการจัดจำหนาย`,
    nameCheckBox: 'distributionChecked',
    nameInput: 'distribution',
    placeholder: `เลือก ช่องทางการจัดจำหนาย`,
    type: 'select_dropdown',
  },
]
