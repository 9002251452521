import { crudConfig as initialCrudConfig } from './crudConfig'
import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import CFilterDrawer from '../../../../../../components/CRUD/components/FilterDrawer'
import { filterItems } from './model/filterItems'
import { defaultFilter } from './model/defaultFilter'
import { handleChange } from '../../../../../../components/CRUD/handler/handleChange'
import { handleFiltering } from '../../../../../../components/CRUD/handler/handleFiltering'
import { fetchDataList } from '../../../../../../components/CRUD/handler/fetchDataList'
import { initialConfig } from '../../../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { mutateFilterPropToBody } from './mutation/filterPropToBody'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import dayjs from 'dayjs'
import { FilterBox } from '../filterBox'
import {
  LoadingCircular,
  StyledCard,
  StyledContainer,
} from '../../../../../Styled'
import { Box, Button, Card, Typography } from '@mui/material'
import { FileDownloadOutlined, FilterListOutlined } from '@mui/icons-material'
import { onDownload } from '../../../../../../components/CRUD/handler/onDownload'
import { StyledContentBox, StyledToolbar } from '../Styled'
import { SortDropdown } from './components/sortDropdown'
import { QuestionContent } from './components/QuestionContent'
import { useDispatch } from 'react-redux'
import { setToggleFilterDrawer } from '../../../../../../redux/slices/table'

export const TestListTab = ({ tab }) => {
  const dispatch = useDispatch()
  const { eTestingDashboard, crudConfig, isLoading } = useSelector(
    (state) => state.crud,
  )
  const { sort, order, filterTotal } = useSelector(
    (state) => ({
      sort: state.table.table.sort,
      order: state.table.table.order,
      filterTotal: state.table.filter.filterTotal,
    }),
    shallowEqual,
  )
  useEffect(() => {
    initialConfig(initialCrudConfig)
  }, [])

  useEffect(() => {
    if (!_.isUndefined(crudConfig) && tab === 'testList') {
      fetchDataList(mutateFilterPropToBody, {}, 'testList').then()
    }
  }, [crudConfig])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }
  const latestUpdated = _.get(eTestingDashboard, 'lastestUpdated', '')

  console.log('render TestListTab')

  return (
    <StyledContainer>
      <Card sx={{ minWidth: 275 }}>
        <StyledContentBox>
          <StyledToolbar sx={{}}>
            <Typography variant="h6">รายละเอียดรายข้อ</Typography>
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <Typography variant="h6" fontSize="20px" sx={{ p: '4px' }}>
                เรียงตาม
              </Typography>
              <SortDropdown />
              <Button
                data-testid={'btn-download'}
                sx={{ height: 40, p: 1 }}
                variant="text"
                startIcon={<FileDownloadOutlined />}
                onClick={() => dispatch(onDownload(sort, order))}
              >
                ดาวน์โหลด
              </Button>

              <Button
                data-testid={'btn-filter'}
                sx={{ height: 40, p: 1 }}
                variant="text"
                startIcon={<FilterListOutlined />}
                onClick={() => dispatch(setToggleFilterDrawer(true))}
              >
                ตัวกรอง {filterTotal != 0 && `(${filterTotal})`}
              </Button>
            </Box>
          </StyledToolbar>
          <FilterBox />
          <Typography variant="body2">
            ข้อมูลเมื่อวันที่{' '}
            {latestUpdated ?? dayjs().format('DD/MM/YYYY 04:00')}
          </Typography>
          <StyledCard
            id="list-table"
            sx={{ padding: '12px 16px 12px 16px', gap: '24px' }}
          >
            <QuestionContent />
          </StyledCard>
          <CFilterDrawer
            handleChange={handleChange}
            handleFiltering={() =>
              handleFiltering(
                mutateFilterSateToProp,
                mutateFilterPropToBody,
                'testList',
              )
            }
            filterItems={filterItems}
            defaultFilter={defaultFilter}
          />
        </StyledContentBox>
      </Card>

      <LoadingCircular isLoading={isLoading} />
    </StyledContainer>
  )
}
