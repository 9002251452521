import { getOptionsReportByKey } from '../../../services/reportComp'
import { store } from '../../../App'
import _ from 'lodash'

export const fetchOptionsReportByKey = async () => {
  const {
    columnSelected,
    reportConfig: { modulePath, moduleId },
  } = store.getState().reportComp
  const { payload: response } = await store.dispatch(
    getOptionsReportByKey({
      body: {
        columnSelected: _.get(columnSelected, 'name'),
        moduleId: moduleId,
      },
      modulePath: modulePath,
    }),
  )
  return _.get(response, 'results', [])
}
