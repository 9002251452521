import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import { ToggleContent } from '../ToggleContent'
import { axisLabel, partTitle } from '../../models/partModel'
import { shallowEqual } from 'react-redux'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import BarChart from 'src/components/Chart/BarChart'
import { DataCard } from '../ModuleCard/DataCard'
import { handleClickViewButton } from '../../handler/handleClickViewButton'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { questionTypeText } from '../../constants/type'
import { generateGradientColors } from '../../handler/handleSetColors'
import AnswerTable from '../AnswerTable'

const OtherEva = () => {
  const { part4Eva } = useSelector(
    (state) => ({
      part4Eva: state.crud?.eEvaluationDashboard?.overview?.part4Eva,
    }),
    shallowEqual,
  )
  return (
    <Box mb={3}>
      <Card>
        <CardContent>
          <ToggleContent
            title={partTitle.part4}
            contentId="otherEva"
            alwaysOpen={true}
            hideIcon={true}
          >
            {_.defaultTo(part4Eva, []).map((item, index) => (
              <SubToggleContent
                key={'otherEva-' + index}
                data={item}
                dataLength={part4Eva.length}
                index={index}
              />
            ))}
          </ToggleContent>
        </CardContent>
      </Card>
    </Box>
  )
}

const SubToggleContent = ({ data, dataLength, index }) => {
  const answer = data?.answer ?? []
  const labels = answer.map((item) => item.no)
  const bgColors = generateGradientColors(answer.length ?? 0)
  const chart = answer.map((item) => item.count)
  const moduleData = preparedModuleData(data)
  const totalAnswer = data.totalAnswer ?? 0
  return (
    <Box key={`sub-toggle-$${data.title}-` + index}>
      <ToggleContent
        title={data.title}
        type={data.type}
        contentId={data.contentId}
        BoxSx={
          dataLength === index + 1
            ? ''
            : { borderBottom: 'solid 1px #00000012' }
        }
      >
        <Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Button
              data-testid="btn-preview"
              variant="outlined"
              size="m"
              endIcon={<ChevronRightIcon color="primary" />}
              onClick={() => {
                handleClickViewButton('part4', index)
              }}
            >
              ดูรายละเอียดคำตอบ
            </Button>
            <Typography variant="body1">
              (ทั้งหมด {totalAnswer} คำตอบ)
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={'column'}
            gap={2}
            alignItems={'start'}
            width={'100%'}
          >
            <DataCard
              dataList={moduleData}
              totalModule={''}
              border={'none'}
              padding={0}
            />
          </Box>
          {data.type !== questionTypeText.FREE_TEXT && (
            <>
              <Box minHeight="250px">
                <BarChart
                  dataSet={{
                    data: chart,
                    backgroundColor: bgColors,
                  }}
                  labels={labels}
                  height={'300px'}
                  showXAxisLabel={true}
                  showYAxisLabel={true}
                  xAxisLabel={axisLabel.x}
                  yAxisLabel={axisLabel.y}
                  showPercent={true}
                  eleId={`part4-${data.title}-${index}`}
                />
              </Box>
              <Box>
                <AnswerTable answers={answer} />
              </Box>
            </>
          )}
        </Box>
      </ToggleContent>
    </Box>
  )
}

const preparedModuleData = (item) => {
  return [
    {
      label: 'จำนวนผู้เข้าอบรม',
      labelVariant: 'body2',
      number: item?.attendeeCount ?? 0,
      hidePercent: true,
      unit: 'คน',
      width: '200px',
      hideClick: true,
    },
    {
      label: 'จำนวนผู้ทำแบบประเมิณ',
      labelVariant: 'body2',
      background: '#4976BA14',
      number: item?.respondentCount ?? 0,
      hidePercent: true,
      unit: 'คน',
      width: '200px',
      hideClick: true,
    },
    {
      label: 'ภาพรวมที่ได้',
      labelVariant: 'body2',
      background: '#4976BA14',
      number: `${item?.overallPercentage ?? 0} %`,
      isString: true,
      hidePercent: true,
      unit: '',
      value: 'ALL',
      type: 'Prospect',
      width: '200px',
      hideClick: true,
    },
  ]
}

export default OtherEva
