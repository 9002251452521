export const modulesProp = {
  toolbar: {
    container: [
      [{ size: [] }],
      [
        'bold',
        'italic',
        'underline',
        { color: [] },
        { background: [] },
      ],
      [{ align: [] }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image', 'video'],
      ['clean'],
    ],
  },
  clipboard: { matchVisual: false },
}
