import * as yup from 'yup'
import { path } from './path'

export const INVENTORY_SETTING = 'INVENTORY'

export const STOCK_PERMISSION = {
  ADMIN: 'ADMIN',
  CREATE: 'CREATE',
  MP: 'MP',
  VM: 'VM',
  MENU: 'MENU',
}

export const STOCK_OWNER = {
  PIC: 'PIC',
  ALL: 'ALL',
}

export const STOCK_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
}

export const STOCK_STATUS_TEXT = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  DELETED: 'Deleted',
}

export const STOCK_STATUS_TEXT_TH = {
  ACTIVE: 'เปิดใช้งาน',
  INACTIVE: 'ปิดใช้งาน',
  DELETED: 'ถูกลบแล้ว',
}

export const STOCK_TYPE = {
  ACADEMY: 'ACADEMY',
  PROVIDER: 'PROVIDER',
}

export const STOCK_TYPE_TEXT = {
  ACADEMY: 'Academy',
  PROVIDER: 'Vendor',
}

export const STOCK_HISTORY_STATUS_TEXT = {
  CREATED: 'สร้าง',
  UPDATED: 'แก้ไข',
  DELETED: 'ลบ',
}

export const headCells = [
  {
    id: 'status',
    label: 'สถานะ',
    hideSortIcon: true,
  },
  {
    id: 'id',
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'warehouseName',
    disablePadding: false,
    label: 'ชื่อคลังทรัพย์สิน',
  },
  {
    id: 'stationName',
    label: 'Station',
    disablePadding: false,
  },
  {
    id: 'warehouseType',
    label: 'ประเภท',
    disablePadding: false,
  },
  {
    id: 'remark',
    label: 'หมายเหตุ',
    disablePadding: false,
  },
  {
    id: 'actionStock',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    viewPath: `${path.inventory}/stock/detail`,
    editPath: `${path.inventory}/stock/form`,
  },
]

export const stockValidationSchema = yup.object({
  warehouseName: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .nullable()
    .max(255, 'ระบุได้ไม่เกิน 255 ตัวอักษร')
    .required('กรุณาระบุชื่อคลังทรัพย์สิน'),
  station: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .nullable()
    .required('กรุณาเลือก Station ประจำ'),
  remark: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .max(3000, 'ระบุได้ไม่เกิน 3,000 ตัวอักษร')
    .nullable(),
})

export const stockValidationProviderSchema = yup.object({
  warehouseName: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .nullable()
    .max(255, 'ระบุได้ไม่เกิน 255 ตัวอักษร')
    .required('กรุณาระบุชื่อคลังทรัพย์สิน'),
  station: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .nullable()
    .required('กรุณาเลือก Station ประจำ'),
  vendor: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .nullable()
    .max(255, 'ระบุได้ไม่เกิน 255 ตัวอักษร')
    .required('กรุณาระบุ Vendor'),
  remark: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .max(3000, 'ระบุได้ไม่เกิน 3,000 ตัวอักษร')
    .nullable(),
})

export const PRODUCT_TYPE = {
  STOCK: 'STOCK',
  STOCK_ASSET: 'STOCK_ASSET',
  ASSET: 'ASSET',
  LICENSE: 'LICENSE',
}

export const PRODUCT_TYPE_TEXT = {
  STOCK: 'Stock',
  STOCK_ASSET: 'Stock Asset',
  ASSET: 'Asset',
  LICENSE: 'License',
}

export const PRODUCT_TYPE_TEXT_HERF = {
  STOCK: 'stock',
  STOCK_ASSET: 'stock',
  ASSET: 'asset',
  LICENSE: 'license',
}

export const PRODUCT_TYPE_VIEW_TEXT = {
  STOCK: 'Stock',
  STOCK_ASSET: 'Stock',
  ASSET: 'Asset',
  LICENSE: 'License & Software',
}

export const PRODUCT_TYPE_TRANSFER = {
  stock: 'STOCK',
  sku: 'STOCK_ASSET',
  asset: 'ASSET',
  license: 'LICENSE',
}

export const TicketStatus = {
  APPROVED: 'APPROVED',
  EDITED_APPROVED: 'EDITED_APPROVED',
  REJECTED: 'REJECTED',
  WAITING_APPROVE: 'WAITING_APPROVE',
  DRAFT: 'DRAFT',
  CANCELED: 'CANCELED',
  DELETED: 'DELETED',
  //Requisition
  WAITING_REQUISITION_COMFIRM: 'WAITING_REQUISITION_COMFIRM',
  WAITING_REQUISITION_APPROVE: 'WAITING_REQUISITION_APPROVE',
  WAITING_RETURN: 'WAITING_RETURN',
  WAITING_RETURN_APPROVE: 'WAITING_RETURN_APPROVE',
  COMPLETED: 'COMPLETED',
  COMPLETED_WITH_CONDITION: 'COMPLETED_WITH_CONDITION',
}

export const TicketStatusText = {
  APPROVED: 'อนุมัติ',
  EDITED_APPROVED: 'แก้ไขและอนุมัติ',
  REJECTED: 'ปฏิเสธ',
  WAITING_APPROVE: 'รออนุมัติ',
  DRAFT: 'ฉบับร่าง',
  CANCELED: 'ยกเลิก',
  DELETED: 'ยกเลิก',
  WAITING_RETURN: 'อนุมัติรายการเบิก',
  WAITING_RETURN_APPROVE: 'อนุมัติคืนทรัพย์สิน',
  COMPLETED: 'อนุมัติคืนทรัพย์สิน',
  COMPLETED_WITH_CONDITION: 'อนุมัติคืนทรัพย์สิน',
}

export const TicketStatusColor = {
  APPROVED: '#1CC54E',
  EDITED_APPROVED: '#1CC54E',
  REJECTED: '#C91432',
  WAITING_APPROVE: '#00008F',
  DRAFT: '#FFB547',
  CANCELED: '#333333',
  DELETED: '#333333',
}

export const ASSET_DATA_FIELD = {
  stock: 'product',
  sku: 'productStockAsset',
  asset: 'productAsset',
  license: 'productLicense',
}

export const ProductCategory = {
  TOOLS: 'TOOLS',
  MEDIA: 'MEDIA',
  GRAPHIC: 'GRAPHIC',
  PHOTOGRAPHY: 'PHOTOGRAPHY',
  EQUIPMENT: 'EQUIPMENT',
}

export const ProductSubCategory = {
  WRITING: 'WRITING',
  TOOLS: 'TOOLS',
  MEDIA: 'MEDIA',
  GRAPHIC: 'GRAPHIC',
  PHOTOGRAPHY: 'PHOTOGRAPHY',
  EQUIPMENT: 'EQUIPMENT',
}

export const ProductMovementType = {
  IMPORT: 'IMPORT',
  TRANSFER_IN: 'TRANSFER_IN',
  TRANSFER_OUT: 'TRANSFER_OUT',
  EDITED: 'EDITED',
  REDUCE: 'REDUCE',
  REQUISITION: 'REQUISITION',
  RETURN: 'RETURN',
  DELETED: 'DELETED',
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  CHANGE_HOLDER: 'CHANGE_HOLDER',
}

export const ProductMovementTypeText = {
  TRANSFER_IN: 'โอนเข้า',
  TRANSFER_OUT: 'โอนออก',
  EDITED: 'แก้ไข',
  REDUCE: 'ปรับลด',
  REQUISITION: 'เบิก',
  RETURN: 'คืน',
  IMPORT: 'นำเข้า',
  DELETED: 'ลบ',
  INACTIVE: 'ปิดใช้งาน',
  ACTIVE: 'เปิดใช้งาน',
  CHANGE_HOLDER: 'เปลี่ยนผู้ถือครอง',
}
export const TICKET_TYPE = {
  TRANSFER: 'TRANSFER',
  REQUISITION: 'REQUISITION',
}

export const RequisitionStatus = {
  WAITING_APPROVE: 'WAITING_APPROVE',
  WAITING_REQUISITION_COMFIRM: 'WAITING_REQUISITION_COMFIRM',
  WAITING_REQUISITION_APPROVE: 'WAITING_REQUISITION_APPROVE',
  WAITING_RETURN: 'WAITING_RETURN',
  WAITING_RETURN_APPROVE: 'WAITING_RETURN_APPROVE',
  COMPLETED: 'COMPLETED',
  COMPLETED_WITH_CONDITION: 'COMPLETED_WITH_CONDITION',
  DRAFT: 'DRAFT',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
  DELETED: 'DELETED',
}

export const RequisitionStatusText = {
  WAITING_APPROVE: 'รอยืนยัน',
  WAITING_REQUISITION_COMFIRM: 'รอยืนยันเบิก', //TO ยืนยัน
  WAITING_REQUISITION_APPROVE: 'รออนุมัติเบิก', //Admin อนุมัติ
  WAITING_RETURN: 'รอคืน',
  WAITING_RETURN_APPROVE: 'รออนุมัติคืน', //reject แล้วกลับไป รอคืน
  COMPLETED: 'เสร็จสิ้น',
  COMPLETED_WITH_CONDITION: 'เสร็จสิ้น',
  DRAFT: 'ฉบับร่าง',
  REJECTED: 'ปฏิเสธ', //เกิดตอนรออนุมัติเบิก
  CANCELED: 'ยกเลิก',
  DELETED: 'ยกเลิก',
  NOT_RETURN: 'ไม่คืน',
}

export const RequisitionStatusColor = {
  WAITING_APPROVE: '#00008F',
  WAITING_REQUISITION_COMFIRM: '#00008F', //TO ยืนยัน
  WAITING_REQUISITION_APPROVE: '#00008F', //Admin อนุมัติ
  WAITING_RETURN: '#F07662',
  WAITING_RETURN_APPROVE: '#00008F', //reject แล้วกลับไป รอคืน
  COMPLETED: '#1CC54E',
  COMPLETED_WITH_CONDITION: '#1CC54E',
  DRAFT: '#FFB547',
  REJECTED: '#C91432',
  CANCELED: '#333333',
  DELETED: '#333333',
}
