import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'
import { CustomDatePicker } from '../CustomDatePicker'
import TextInput from '../../../../components/Input/TextInput'
import { handleNumberKeyDown, handlePasteFormat } from '../../../../utils/lib'
import { regex } from '../../../../components/CRUD/enum/regex'
import { handleInputNumber } from '../../../../components/CRUD/handler/handleInputNumber'
import SelectIcon from '../../../../components/redux/SelectIcon'

import {
  setHandleChange,
  setToggleFilterDrawer,
} from '../../../../redux/slices/table'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
  StyledList,
} from '../../../FilterDrawer/Styled'
import { store } from '../../../../App'
import _ from 'lodash'
import { CustomDropdown } from '../CustomDropdown'
import { setFieldValue } from '../../../../redux/slices/crud'

const CFilterDrawer = ({
  handleChange,
  handleFiltering,
  filterItems,
  defaultFilter,
}) => {
  const { open, filterState, filterStateMemo } = useSelector((state) => ({
    open: state.table.filter.isFilterDrawer,
    filterState: state.table.filterState,
    filterStateMemo: state.table.filterStateMemo,
  }))
  const dispatch = useDispatch()

  const handleClose = () => {
    if (!_.isEmpty(filterStateMemo)) {
      dispatch(setHandleChange({ key: 'filterState', value: filterStateMemo }))
    }
    dispatch(setToggleFilterDrawer(false))
  }

  useEffect(() => {
    if (filterStateMemo) {
      return
    }
    dispatch(setHandleChange({ key: 'filterState', value: defaultFilter }))
  }, [open])

  return (
    <StyledDrawer open={open} onClose={handleClose}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <ListCheckbox
        listArray={filterItems}
        handleChange={handleChange}
        filterState={filterState}
      />
      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => {
            filterItems.map((item) => {
              if (item.type === 'select_dropdown') {
                dispatch(
                  setFieldValue({
                    key: `formData.${item.nameInput}-filter-dropdown`,
                    value: '',
                  }),
                )
              }
            })
            dispatch(
              setHandleChange({ key: 'filterState', value: defaultFilter }),
            )
          }}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            dispatch(handleFiltering())
            dispatch(
              setHandleChange({ key: 'filterStateMemo', value: filterState }),
            )
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default CFilterDrawer

export const ListCheckbox = ({ listArray, handleChange }) => {
  const { filterState } = store.getState().table
  const { filterList } = store.getState().crud

  return listArray.map((item, index) => {
    let list = item?.list ?? []
    if (filterList?.[item.nameInput]) {
      list = filterList?.[item.nameInput]
    }

    return (
      <StyledGroupBox key={index}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': `check-${item.nameCheckBox}`,
                }}
                name={item.nameCheckBox}
                checked={filterState[item.nameCheckBox]}
                onChange={(e) => {
                  handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    filter: filterState,
                  })
                }}
              />
            }
            label={item.label}
            sx={!filterState[item.nameCheckBox] ? { marginBottom: -1 } : {}}
          />

          {filterState[item.nameCheckBox] && (
            <>
              {item.type === 'text' && (
                <InputBase
                  sx={{ borderRadius: 0 }}
                  type="basic"
                  inputProps={{ 'data-testid': `input-${item.nameInput}` }}
                  name={item.nameInput}
                  disabled={!filterState[item.nameCheckBox]}
                  placeholder={item.placeholder}
                  value={filterState[item.nameInput]}
                  onChange={(e) => {
                    handleChange({
                      key: e.target.name,
                      value: e.target.value,
                      filter: filterState,
                      filterState,
                    })
                  }}
                />
              )}
              {item.type === 'checkbox' && (
                <StyledList>
                  <ListSubCheckbox
                    list={list}
                    listKey={item.nameInput}
                    handleChange={handleChange}
                  />
                </StyledList>
              )}
              {item.type === 'daterange' && (
                <CustomDatePicker filterState={filterState} item={item} />
              )}
              {item.type === 'number_between' && (
                <Box display="flex" gap={2}>
                  <TextInput
                    id={`${item.inputFrom}`}
                    name={`${item.inputFrom}`}
                    labelText="ตั้งแต่"
                    type="basic"
                    placeholder={item.placeholder}
                    inputProps={{
                      'data-testid': `input-${item.inputFrom}`,
                      onInput: (e) => {
                        handleInputNumber(e, regex.NUMBER)
                      },
                      onKeyDown: (e) => {
                        handleNumberKeyDown(e, ['.'])
                      },
                      onPaste: handlePasteFormat,
                    }}
                    disabled={!filterState[item.nameCheckBox]}
                    // boxSx={{ mx: 3 }}
                    value={filterState[`${item.inputFrom}`]}
                    onChange={(e) => {
                      handleChange({
                        key: e.target.name,
                        value: e.target.value,
                        filter: filterState,
                        filterState,
                      })
                    }}
                    isShowTextError={false}
                  />
                  <TextInput
                    id={`${item.inputTo}`}
                    name={`${item.inputTo}`}
                    labelText="ถึง"
                    type="basic"
                    placeholder={item.placeholder}
                    inputProps={{
                      'data-testid': `input-${item.inputTo}`,
                      onInput: (e) => {
                        handleInputNumber(e, regex.NUMBER)
                      },
                      onKeyDown: (e) => {
                        handleNumberKeyDown(e, ['.'])
                      },
                      onPaste: handlePasteFormat,
                    }}
                    disabled={!filterState[item.nameCheckBox]}
                    // boxSx={{ mx: 3 }}
                    value={filterState[`${item.inputTo}`]}
                    onChange={(e) => {
                      handleChange({
                        key: e.target.name,
                        value: e.target.value,
                        filter: filterState,
                        filterState,
                      })
                    }}
                    isShowTextError={false}
                  />
                </Box>
              )}
              {item.type === 'float_between' && (
                <Box display="flex" gap={2}>
                  <TextInput
                    id={`${item.inputFrom}`}
                    name={`${item.inputFrom}`}
                    labelText="ตั้งแต่"
                    type="basic"
                    placeholder={item.placeholder}
                    inputProps={{
                      'data-testid': `input-${item.inputFrom}`,
                      onInput: (e) => {
                        handleInputNumber(e, regex.FLOAT)
                      },
                      onKeyDown: (e) => {
                        handleNumberKeyDown(e)
                      },
                      onPaste: handlePasteFormat,
                    }}
                    disabled={!filterState[item.nameCheckBox]}
                    // boxSx={{ mx: 3 }}
                    value={filterState[`${item.inputFrom}`]}
                    onChange={(e) => {
                      handleChange({
                        key: e.target.name,
                        value: e.target.value,
                        filter: filterState,
                        filterState,
                      })
                    }}
                    isShowTextError={false}
                  />
                  <TextInput
                    id={`${item.inputTo}`}
                    name={`${item.inputTo}`}
                    labelText="ถึง"
                    type="basic"
                    placeholder={item.placeholder}
                    inputProps={{
                      'data-testid': `input-${item.inputTo}`,
                      onInput: (e) => {
                        handleInputNumber(e, regex.FLOAT)
                      },
                      onKeyDown: (e) => {
                        handleNumberKeyDown(e)
                      },
                      onPaste: handlePasteFormat,
                    }}
                    disabled={!filterState[item.nameCheckBox]}
                    // boxSx={{ mx: 3 }}
                    value={filterState[`${item.inputTo}`]}
                    onChange={(e) => {
                      handleChange({
                        key: e.target.name,
                        value: e.target.value,
                        filter: filterState,
                        filterState,
                      })
                    }}
                    isShowTextError={false}
                  />
                </Box>
              )}
              {item.type === 'number_day' && (
                <Box display="flex" gap={2} alignItems="center">
                  <TextInput
                    id={`${item.inputYear}`}
                    name={`${item.inputYear}`}
                    labelText="ภายใน"
                    type="basic"
                    inputProps={{
                      'data-testid': `input-${item.inputYear}`,
                      onInput: (e) => {
                        handleInputNumber(e, regex.NUMBER)
                      },
                      onKeyDown: (e) => {
                        handleNumberKeyDown(e, ['.'])
                      },
                      onPaste: handlePasteFormat,
                    }}
                    disabled={!filterState[item.nameCheckBox]}
                    // boxSx={{ mx: 3 }}
                    value={filterState[`${item.inputYear}`]}
                    onChange={(e) => {
                      handleChange({
                        key: e.target.name,
                        value: e.target.value,
                        filter: filterState,
                        filterState,
                      })
                    }}
                    isShowTextError={false}
                  />
                  <Typography mt={3}>ปี</Typography>
                  <TextInput
                    id={`${item.inputMonth}`}
                    name={`${item.inputMonth}`}
                    labelText=" "
                    type="basic"
                    inputProps={{
                      'data-testid': `input-${item.inputMonth}`,
                      onInput: (e) => {
                        handleInputNumber(e, regex.NUMBER)
                      },
                      onKeyDown: (e) => {
                        handleNumberKeyDown(e, ['.'])
                      },
                      onPaste: handlePasteFormat,
                    }}
                    disabled={!filterState[item.nameCheckBox]}
                    // boxSx={{ mx: 3 }}
                    value={filterState[`${item.inputMonth}`]}
                    onChange={(e) => {
                      handleChange({
                        key: e.target.name,
                        value: e.target.value,
                        filter: filterState,
                        filterState,
                      })
                    }}
                    isShowTextError={false}
                  />
                  <Typography mt={3}>เดือน</Typography>
                  <TextInput
                    id={`${item.inputDay}`}
                    name={`${item.inputDay}`}
                    labelText=" "
                    type="basic"
                    inputProps={{
                      'data-testid': `input-${item.inputDay}`,
                      onInput: (e) => {
                        handleInputNumber(e, regex.NUMBER)
                      },
                      onKeyDown: (e) => {
                        handleNumberKeyDown(e, ['.'])
                      },
                      onPaste: handlePasteFormat,
                    }}
                    disabled={!filterState[item.nameCheckBox]}
                    // boxSx={{ mx: 3 }}
                    value={filterState[`${item.inputDay}`]}
                    onChange={(e) => {
                      handleChange({
                        key: e.target.name,
                        value: e.target.value,
                        filter: filterState,
                        filterState,
                      })
                    }}
                    isShowTextError={false}
                  />
                  <Typography mt={3}>วัน</Typography>
                </Box>
              )}
              {item.type === 'select' && (
                <SelectIcon
                  dataTestId={`${item.nameInput}`}
                  name={`${item.nameInput}`}
                  listOption={list}
                  placeholder={item.placeholder}
                  value={filterState[item.nameInput]}
                  handleChange={(e) => {
                    handleChange({
                      key: item.nameInput,
                      value: e.target.value,
                      filter: filterState,
                      filterState,
                    })
                  }}
                />
              )}
              {item.type === 'select_dropdown' && (
                <CustomDropdown
                  id={item.nameInput}
                  name={item.nameInput}
                  labelName=""
                  fieldName={`${item.nameInput}-filter-dropdown`}
                  placeholder={item.placeholder}
                  isCustomOption={true}
                  customOptions={{ isLoading: false, options: list }}
                  handleExtraChange={(event, value) => {
                    handleChange({
                      key: item.nameInput,
                      value: value?.value ?? '',
                      filter: filterState,
                      filterState,
                    })
                  }}
                />
              )}
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
    )
  })
}

export const ListSubCheckbox = ({ list, listKey, handleChange }) => {
  const { filterState } = useSelector((state) => state.table)
  return list.map((item, index) => (
    <FormControlLabel
      key={index}
      label={item.label}
      control={
        <Checkbox
          inputProps={{ 'data-testid': `check-${item.name}` }}
          name={item.name}
          checked={filterState[listKey][item.name]}
          onChange={(e) => {
            handleChange({
              key: e.target.name,
              value: e.target.checked,
              listKey,
              filter: filterState,
            })
          }}
        />
      }
    />
  ))
}
