import { Box, Button, Typography } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { FilterBox } from '../FilterBox'
import { shallowEqual, useSelector } from 'react-redux'
import dayjs from 'dayjs'

const LeftInfo = ({ uuid }) => {
  const { latestUpdated } = useSelector(
    (state) => ({
      latestUpdated: state.crud.eEvaluationDashboard?.latestUpdated,
    }),
    shallowEqual,
  )
  const url = `/e-evaluation/version/edit/${uuid}?tab=feedback`
  return (
    <Box>
      <Box display="flex" gap={2}>
        <Typography variant="h6">ภาพรวม</Typography>
        <Button
          variant="contained"
          onClick={() => {
            window.open(`${url}`, '_blank')
          }}
          size="m"
          endIcon={<ChevronRightIcon />}
          sx={{ padding: '8px 16px 12px 20px' }}
        >
          ดูการตอบรับ
        </Button>
      </Box>
      <Box mt={2}>
        <FilterBox />
        <Typography variant="body2">
          ข้อมูลเมื่อวันที่{' '}
          {latestUpdated ?? dayjs().format('DD/MM/YYYY 02:00')}
        </Typography>
      </Box>
    </Box>
  )
}

export default LeftInfo
