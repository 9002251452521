import { store } from '../../../App'
import {
  setColumnSelected,
  setReduxValue,
} from '../../../redux/slices/reportComp'
import _ from 'lodash'
import { TYPE_OF_VALUE } from '../../../constants/reportCourseSetting/reportCourseSetting'
import { object } from 'yup'
import dayjs from 'dayjs'
import {
  validateValueTypeArray,
  validateValueTypeObj,
  validateValueTypeString,
} from '../model'
import { handleFilterColumnMixModule } from './handleFilterColumnMixModule'

export const handleAddFilter = () => async (dispatch) => {
  const { displayFilters, columnSelected, value, options, reportConfig } =
    store.getState().reportComp
  const { moduleId } = reportConfig

  let formattedValue = value
  let optionSelected = []
  let label = ''
  if (columnSelected.type === TYPE_OF_VALUE.DATE_RANGE) {
    const { startDate, endDate } = value
    label = `${dayjs(startDate).format('DD/MM/YYYY')} -  ${dayjs(
      endDate,
    ).format('DD/MM/YYYY')}`
  } else if (
    columnSelected.type === TYPE_OF_VALUE.INPUT_NUMBER ||
    columnSelected.type === TYPE_OF_VALUE.INPUT_TEXT_BOX ||
    columnSelected.type === TYPE_OF_VALUE.INPUT_TEXT
  ) {
    label = value
    if (columnSelected.value == 'workDistance') {
      label = label + ' ปี'
    }
    if (
      ['trainerIdList', 'createdBy', 'updatedBy'].includes(
        columnSelected?.value,
      ) &&
      moduleId === 'REPORT_MIX_MODULE'
    ) {
      optionSelected = _.filter(options, (opt) => opt.value === value)
      label = _.get(optionSelected, '[0].label', '')
    }
  } else if (columnSelected.type === TYPE_OF_VALUE.DROPDOWN_SINGLE) {
    optionSelected = _.filter(options, (opt) => opt.value === value)
    label = _.get(optionSelected, '[0].label', '')
  } else if (columnSelected.type === TYPE_OF_VALUE.DROPDOWN_MULTI) {
    formattedValue = _.map(value, (val) => val.value)
    label = _.reduce(
      formattedValue,
      (acc, val, idx) => {
        let label = acc
        _.map(options, (opt) => {
          if (val === opt.value) {
            label += opt.label
            if (idx !== formattedValue.length - 1)
              label += [
                'courseNameEN',
                'courseNameTH',
                'courseNameForLearner',
              ].includes(columnSelected.value)
                ? ',&&'
                : ', '
          }
        })
        return label
      },
      '',
    )
  }

  const id =
    displayFilters.length > 0
      ? +displayFilters[displayFilters.length - 1].id + 1
      : 1
  const newFilter = {
    id: id,
    key: columnSelected.value,
    title: columnSelected.label,
    value: formattedValue,
    typeOfValue: columnSelected.type,
    label: label,
  }
  const validationResults = await validationFilter(
    newFilter,
    columnSelected.type,
  )

  if (_.isEmpty(validationResults.inner)) {
    const newFilters = [...displayFilters, newFilter]
    await dispatch(setReduxValue({ key: 'displayFilters', value: newFilters }))
    await dispatch(setColumnSelected(null))
    await dispatch(setReduxValue({ key: 'options', value: [] }))
    if (
      moduleId === 'REPORT_MIX_MODULE' &&
      ['courseNameEN', 'courseNameTH', 'courseNameForLearner'].includes(
        columnSelected.value,
      )
    ) {
      await dispatch(
        handleFilterColumnMixModule(
          columnSelected.value,
          formattedValue.length,
        ),
      )
    }
  } else {
    dispatch(setReduxValue({ key: 'error', value: validationResults.message }))
  }
}

const validationFilter = async (filter, typeOfValue) => {
  let validateSchema = object().shape({
    value:
      typeOfValue !== TYPE_OF_VALUE.DROPDOWN_MULTI
        ? typeOfValue !== TYPE_OF_VALUE.DATE_RANGE
          ? validateValueTypeString
          : validateValueTypeObj
        : validateValueTypeArray,
  })

  return await validateSchema
    .validate(filter, { abortEarly: false })
    .catch((e) => e)
}
