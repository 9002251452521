import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { fetchDetail } from '../../../../components/CRUD/handler/fetchDetail'
import { useSelector } from 'react-redux'
import { crudConfig, crudConfig as initialCrudConfig } from '../crudConfig'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { getBodyMutation } from '../Form/mutation/get'
import { useDispatch } from 'react-redux'
import { setFieldValue } from '../../../../redux/slices/crud'
import Header from './components/header'
import { Box } from '@mui/material'
import TabMenu from './components/tabMenu'
import { StyledFormWrapper } from '../Styled'
import { TesterListTab } from './components/testerListTab'
import { TestListTab } from './components/testTab'
import { OverviewTab } from './components/overviewTab'

export const ETestingDashboardView = () => {
  const dispatch = useDispatch()
  const { uuid } = useParams()
  const {
    //formData, isLoading,
    tab,
  } = useSelector((state) => state.crud)

  useEffect(() => {
    initialConfig(initialCrudConfig)
    if (uuid && !_.isUndefined(crudConfig))
      fetchDetail(uuid, getBodyMutation).then()
  }, [])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }
  useEffect(() => {
    if (tab !== 'testerList' && tab !== 'testList') {
      dispatch(setFieldValue({ key: 'tab', value: 'overview' }))
    }
  }, [tab])

  return (
    <>
      {!_.isUndefined(crudConfig) && (
        <Box sx={{ mx: 3 }}>
          <div>
            <Header />
            <TabMenu tab={tab} />
            <FormContent />
          </div>
        </Box>
      )}
    </>
  )
}

export const FormContent = () => {
  const { tab } = useSelector((state) => state.crud)
  return (
    <StyledFormWrapper sx={{ pt: 2 }}>
      {tab === 'overview' && <OverviewTab tab={tab} />}
      {tab === 'testerList' && <TesterListTab tab={tab} />}
      {tab === 'testList' && <TestListTab tab={tab} />}
    </StyledFormWrapper>
  )
}
