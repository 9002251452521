import _ from 'lodash'
import {
  startLoading,
  stopLoading,
  setInitialTable,
  setSelected,
} from '../../../../../../../../redux/slices/table2'
import { eTestingDashboardTestList } from '../../../../../../../../utils/apiPath'
import { store } from '../../../../../../../../App'
import dayjs from 'dayjs'
import callAxios from '../../../../../../../../utils/baseService'

export const headCellsClass = [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'testerCode',
    label: 'รหัสผู้สอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'name',
    label: 'ชื่อ-สกุล',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'actionMcDashboard',
    label: 'คำสั่ง',
    disablePadding: false,
    hideDelete: true,
    hideEdit: true,
    hideSortIcon: true,
    viewPath: ``,
    minWidth: '40px',
  },
]

export const fetchData =
  ({ table, courseType }) =>
  async (dispatch) => {
    const realPage = table.page <= 0 ? 1 : +table.page
    dispatch(startLoading())
    const headCells = headCellsClass
    try {
      const { filters } = store.getState().crud.eTestingDashboard
      const station = _.defaultTo(filters?.station, []).map((item) => item.uuid)
      const body = {
        limit: _.get(table, 'limit', '100'),
        page: realPage - 1,
        status: courseType ?? '',
        station: station,
        startDate: _.defaultTo(
          filters?.startDate,
          dayjs().startOf('month').format('YYYY-MM-DD'),
        ),
        endDate: _.defaultTo(filters?.endDate, dayjs().format('YYYY-MM-DD')),
      }
      await callAxios
        .post(eTestingDashboardTestList, body)
        .then(({ data }) => {
          const results = _.get(data, 'results', [])
          dispatch(
            setInitialTable({
              rows: prepareRow(results),
              isCheckBox: false,
              allCount: _.get(data, 'totalCount', 0),
              headCells: headCells,
              searchKey: 'name',
              hideFilter: true,
            }),
          )
          dispatch(setSelected([]))
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (e) {
      dispatch(
        setInitialTable({
          rows: [],
          allCount: 0,
          headCells: headCells,
          isCheckBox: false,
        }),
      )
      return e.message?.includes('method')
    }
    dispatch(stopLoading())
  }

const prepareRow = (data) => {
  return data.map((ele) => {
    return {
      ...ele,
    }
  })
}
