import {
  setInitialTable,
  startLoading,
  stopLoading,
} from '../../../redux/slices/table'
import _ from 'lodash'
import { store } from '../../../App'
import callAxios from '../../../utils/baseService'
import { convertFormatDateTime, validatePermission } from '../../../utils/lib'
import { ROW_PAGE_PER_LIST } from '../../../constants/table'
import { handleDeleteClick } from './handleDeleteClick'
import { vendorTypeTextEnum } from '../../../modules/MasterData/VendorManagement/Listing/model/vendorTypeEnum'
import { setChange } from '../../../redux/slices/eContent/content'
import { LIST_TAB } from '../../../modules/EContent/Content/components/tabMenu/model'

export const fetchDashboardTable = async (
  mutatePropToBody,
  props = {},
  downloadName,
  tableHeadText,
) => {
  const { setTableProps } = props
  const { table, search, filter: filterState } = store.getState().table
  const { crudConfig } = store.getState().crud
  const headerCells = _.get(crudConfig, 'headerCells', () => {})
  const { page } = table
  const { status, filterProp } = filterState
  const { searchText } = search

  const realPage = page <= 0 ? 1 : +page
  const tableProps = table
  const filter = filterProp

  const body = {
    limit: _.get(tableProps, 'limit', ROW_PAGE_PER_LIST[0].value),
    page: realPage,
    order: tableProps.order.toString().toUpperCase(),
    sort: _.get(tableProps, 'sort', 'updatedAt'),
    quickSearch: searchText,
    ...mutatePropToBody(),
    createdBy: _.get(filter, 'createdBy', ''),
    updatedBy: _.get(filter, 'updatedBy', ''),
  }

  body.openDateFinish = body.openDateFinish
    ? convertFormatDateTime({
        value: _.get(body, 'openDateFinish', ''),
        type: 'dateDb',
      })
    : ''
  body.openDateStart = body.openDateStart
    ? convertFormatDateTime({
        value: _.get(body, 'openDateStart', ''),
        type: 'dateDb',
      })
    : ''

  store.dispatch(startLoading())
  const isCancel = await callAxios
    .post(crudConfig.filterPath, body, { 'x-type': crudConfig.moduleId })
    .then((res) => {
      const row = _.get(res, 'data.result', []).map((item, index) => {
        const startPublishDate = !_.isEmpty(item.startPublishDate)
          ? convertFormatDateTime({
              value: _.get(item, 'startPublishDate', ''),
              type: 'date',
            })
          : ''
        const endPublishDate = !_.isEmpty(item.endPublishDate)
          ? `-${convertFormatDateTime({
              value: _.get(item, 'endPublishDate', ''),
              type: 'date',
            })}`
          : ''
        //eContent Status Transform
        if (
          !_.isEmpty(downloadName) &&
          (downloadName === 'Knowledge Base' || downloadName === 'eContent')
        ) {
          if (
            _.get(item, 'status', 'DRAFT') !== 'DRAFT' &&
            _.get(item, 'status', 'DRAFT') !== 'DELETED'
          ) {
            if (_.get(item, 'isActive', false) === true) {
              _.set(item, 'status', 'READY')
            } else {
              _.set(item, 'status', 'READYINACTIVE')
            }
          }
        }

        return {
          ...item,
          description: _.isEmpty(_.get(item, 'description', '-'))
            ? '-'
            : _.get(item, 'description', '-'),
          categoryName: _.get(item, 'category.name', ''),
          stockCategoryName: _.get(item, 'stockCategory.name', ''),
          station: _.get(item, 'station.station', ''),
          createdAt: convertFormatDateTime({
            value: item.createdAt,
            type: 'date',
          }),
          publishDate: `${startPublishDate}${endPublishDate}`,
          periodStart:
            !_.isEmpty(item.periodStart) &&
            convertFormatDateTime({
              value: item.periodStart,
              type: 'date',
            }),
          no: index + 1,
          vendorType: vendorTypeTextEnum?.[_.get(item, 'vendorType', '')] ?? '',
          pathCount: `${_.get(item, 'pathCount', 0)} Path ${_.get(
            item,
            'courseCount',
            0,
          )} หลักสูตร`,
        }
      })
      const header =
        crudConfig.moduleId === 'E_CONTENT'
          ? checkHeaderPermission(headerCells())
          : headerCells()
      store.dispatch(
        setInitialTable({
          tableHeadText: tableHeadText,
          sxTable: {
            border: '1px solid #DBE4F199',
            margin: '12px 16px',
            width: 'calc(100% - 36px)',
          },
          onDownload: 'hidden',
          isShowPagination: false,
          isCheckBox: false,
          hideFilter: true,
          rows: row,
          allCount: _.get(res, 'data.totalCount', 0),
          headCells: header,
          placeholder: 'ค้นหา',
          searchKey: 'name',
          status: status,
          onDelete: (selected) => {
            handleDeleteClick(
              selected.uuid,
              mutatePropToBody,
              selected?.isPinned || false,
            )
          },
          ...setTableProps,
        }),
      )
      if (crudConfig.moduleId === 'E_CONTENT') {
        store.dispatch(
          setChange({
            key: 'totalPinned',
            value: _.get(res, 'data.totalPinned', 0),
          }),
        )
      }
    })
    .catch((e) => {
      store.dispatch(
        setInitialTable({
          tableHeadText: tableHeadText,
          onDownload: 'hidden',
          sxTable: {
            border: '1px solid #DBE4F199',
            margin: '12px 16px',
            width: 'calc(100% - 36px)',
          },
          isShowPagination: false,
          isCheckBox: false,
          hideFilter: true,
          rows: [],
          allCount: 0,
          headCells: headerCells(),
          placeholder: 'ค้นหา',
        }),
      )
      return e.message?.includes('method')
    })
  if (!isCancel) {
    store.dispatch(stopLoading())
  }
}

const checkHeaderPermission = (header) => {
  const action = header.pop()
  const user = JSON.parse(localStorage.getItem('user'))
  const { listTab } = store.getState().crud
  const hasPermissionEdit = validatePermission({
    user: user,
    moduleType: 'E_CONTENT_AND_KB',
    permission:
      listTab == LIST_TAB.eContent
        ? ['EDIT', 'EDIT_ECONTENT']
        : ['EDIT', 'EDIT_KB'],
  })
  const hasPermissionDelete = validatePermission({
    user: user,
    moduleType: 'E_CONTENT_AND_KB',
    permission:
      listTab == LIST_TAB.eContent
        ? ['DELETE', 'DELETE_ECONTENT']
        : ['DELETE', 'DELETE_KB'],
  })
  header.push({
    ...action,
    hideEdit: hasPermissionEdit ? false : true,
    hideDelete: hasPermissionDelete ? false : true,
  })
  return header
}
