import dayjs from 'dayjs'

export const overviewEnum = {
  latestUpdated: dayjs().startOf('month').format('YYYY-MM-DD HH:mm'),
  courseEva: {
    attendeeCount: 0,
    respondentCount: 0,
    overallPercentagePart: 0.0,
  },
  trainerEva: [],
  courseManageEva: {
    attendeeCount: 0,
    respondentCount: 0,
    overallPercentagePart: 0.0,
  },
  part4Eva: [],
}

export const evaluationDrawerEnum = {
  isOpen: false,
}

export const filtersDrawerEnum = {
  isOpen: false,
  startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
  endDate: dayjs().format('YYYY-MM-DD'),
  courseFilter: '',
  trainigDateFilter: '',
  trainerNameFilter: '',
  distributionFilter: '',
  filterTotal: 0,
  //Course
  //Training Date
  //Trainer name
  //Distribution Channel
}
