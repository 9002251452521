import { store } from '../../../../../../../App'

export const mutateFilterSateToProp = () => {
  const { filterState } = store.getState().table
  let status = []

  return {
    distribution: filterState.distributionChecked
      ? filterState.distribution
      : '',
    agentCode: filterState.agentCodeChecked ? filterState.agentCode : '',
    testDateStart: filterState.testDateChecked ? filterState.testDateStart : '',
    testDateFinish: filterState.testDateChecked
      ? filterState.testDateFinish
      : '',
    unitCode: filterState.unitCode ? filterState.unitCode : '',
    SRAM: filterState.SRAM ? filterState.SRAM : '',
    RAM: filterState.RAM ? filterState.RAM : '',
    scoreFrom: filterState.scoreChecked ? filterState.scoreFrom : '',
    scoreTo: filterState.scoreChecked ? filterState.scoreTo : '',
    status: status,
  }
}
