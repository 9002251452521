import { store } from "../../../../../../App"
import { overviewEnum } from "../enums/drawerEnum"
import _ from "lodash"

export const courseEvaPartModel = () => {
  const { eEvaluationDashboard } = store.getState().crud
  const overview = eEvaluationDashboard?.overview || overviewEnum
  return [
    {
      label: 'จำนวนผู้เข้าอบรม',
      labelVariant: 'body2',
      number: _.get(overview?.courseEva, 'attendeeCount', 0),
      hidePercent: true,
      unit: 'คน',
      width: '200px',
      hideClick: true,
    },
    {
      label: 'จำนวนผู้ทำแบบประเมิณ',
      labelVariant: 'body2',
      background: '#4976BA14',
      number: _.get(overview?.courseEva, 'respondentCount', 0),
      hidePercent: true,
      unit: 'คน',
      width: '200px',
      hideClick: true,
    },
    {
      label: 'ภาพรวมที่ได้',
      labelVariant: 'body2',
      background: '#4976BA14',
      number: `${_.get(overview?.courseEva, 'overallPercentage', 0)} %`,
      isString: true,
      hidePercent: true,
      unit: '',
      value: 'ALL',
      type: 'Prospect',
      width: '200px',
      hideClick: true,
    },
  ]
}

export const courseManagePartModel = () => {
  const { eEvaluationDashboard } = store.getState().crud
  const overview = eEvaluationDashboard?.overview || overviewEnum
  return [
    {
      label: 'จำนวนผู้เข้าอบรม',
      labelVariant: 'body2',
      number: _.get(overview?.courseManageEva, 'attendeeCount', 0),
      hidePercent: true,
      unit: 'คน',
      width: '200px',
      hideClick: true,
    },
    {
      label: 'จำนวนผู้ทำแบบประเมิณ',
      labelVariant: 'body2',
      background: '#4976BA14',
      number: _.get(overview?.courseManageEva, 'respondentCount', 0),
      hidePercent: true,
      unit: 'คน',
      width: '200px',
      hideClick: true,
    },
    {
      label: 'ภาพรวมที่ได้',
      labelVariant: 'body2',
      background: '#4976BA14',
      number: `${_.get(overview?.courseManageEva, 'overallPercentage', 0)} %`,
      isString: true,
      hidePercent: true,
      unit: '',
      value: 'ALL',
      type: 'Prospect',
      width: '200px',
      hideClick: true,
    },
  ]
}