import React from 'react'
import _ from 'lodash'
import { useLocalStorage } from '@rehooks/local-storage'
import Box from '@mui/material/Box'

import Information from './Information'
import Footer from './Footer'
import MainInformation from './MainInformation'
import LicenseSection from './License'
import EducationSection from './Education'
import CourseSection from './Course'
import ExamSection from './Exam'
import PointSection from './Point'
import BadgeSection from './Badge'

const Index = ({ type, editable, SyncData }) => {
  const [user] = useLocalStorage('user')
  const permissionList = _.get(user, 'permission', [])
  const hasPermission = _.find(permissionList, (item) => {
    return (
      item.owner === 'ALL' &&
      item.permission === 'MANAGE_POINT' &&
      item.module === 'LEARNING_POINT'
    )
  })
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <MainInformation SyncData={SyncData} />
      <Information type={type} editable={editable} />
      <EducationSection />
      <LicenseSection />
      <CourseSection type={type} />
      <ExamSection />
      {hasPermission && (
        <>
          <PointSection />
          <BadgeSection />
        </>
      )}
      <Footer />
    </Box>
  )
}

export default Index
