import { useSelector } from 'react-redux'
import { setSelected } from '../../../../../../../redux/slices/table'
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { useEffect } from 'react'
import { ChevronLeft, ChevronRight, CopyAll } from '@mui/icons-material'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import ViewString from './ViewString'

export const QuestionContent = () => {
  const dispatch = useDispatch()
  const { rows, allCount, selected } = useSelector((state) => state.table.table)
  useEffect(() => {
    dispatch(setSelected([0]))
  }, [])
  let optionsArray = []
  for (let i = 1; i < 10 + 1; i++) {
    optionsArray.push(i)
  }

  return (
    <>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <Typography variant="h6" fontSize="20px" sx={{ p: '4px' }}>
          ทั้งหมด
        </Typography>
        <FormControl>
          <Select
            labelId="select-label"
            id="simple-select"
            value={selected[0] + 1}
            defaultValue={1}
            onChange={(e) => dispatch(setSelected(e.target.value))}
          >
            {optionsArray.map((data) => {
              return (
                <MenuItem value={data} key={data}>
                  {data}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <Typography sx={{ padding: '6px' }}>/ {allCount}</Typography>
        <IconButton
          data-testid={`btn-previous-${selected[0]}`}
          disabled={selected[0] === 0}
          color="primary"
          onClick={() => dispatch(setSelected(selected[0] - 1))}
        >
          <ChevronLeft />
        </IconButton>
        <IconButton
          data-testid={`btn-next-${selected[0]}`}
          disabled={selected[0] === allCount - 1 || allCount === 0}
          color="primary"
          onClick={() => dispatch(setSelected(selected[0] + 1))}
        >
          <ChevronRight />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <Typography sx={{ padding: '4px' }}>คำถาม</Typography>
        <IconButton
          data-testid={`btn-next-${selected[0]}`}
          disabled={selected[0] === allCount}
          color="primary"
          onClick={() => dispatch(setSelected(selected[0] + 1))}
        >
          <CopyAll />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', gap: '8px', padding: '4px' }}>
        <Typography variant="h6" fontSize="20px" sx={{ height: '100%' }}>
          Q{rows[selected[0]]?.no}.
        </Typography>
        <Typography variant="h6" fontSize="20px">
          {_.get(rows[selected[0]], `question`, '')}
        </Typography>
      </Box>
      <Divider variant="middle" sx={{ paddingTop: '24px' }} />
      <Box sx={{ display: 'flex', gap: '16px', padding: '24px 0 0 4px' }}>
        <Typography>คำตอบ</Typography>
        <Typography>
          (ทั้งหมด {_.get(rows[selected[0]], `answer.length`, 0)})
        </Typography>
      </Box>
      <Box sx={{ padding: '24px 0 0 4px' }}>
        {_.get(rows[selected[0]], `answer`, []).map((data, i) => {
          return (
            <Box
              key={i}
              sx={{
                display: 'flex',
                gap: '8px',
                paddingTop: '24px',
              }}
            >
              <ViewString
                value={data?.answerText}
                customSx={{ width: '50%' }}
              />
              <Box sx={{ display: 'flex', gap: '8px', width: '50%' }}>
                <Box
                  sx={{
                    height: '100%',
                    width: data?.answerPercent,
                    backgroundColor: data?.isRightAnswer
                      ? '#1CC54E'
                      : '#C9143280',
                    borderRadius: '4px',
                  }}
                />
                <Typography
                  sx={{ p: '4px', whiteSpace: 'nowrap', margin: 'auto 0' }}
                >
                  {data?.answerCount} ({data?.answerPercent})
                </Typography>
              </Box>
            </Box>
          )
        })}
      </Box>
    </>
  )
}
