//import { crudConfig } from '../../crudConfig'

export const breadcrumb = () => [
  { title: 'E-Testing', link: '/', pointer: false },
  { title: 'Dashboard', link: '/e-testing/Dashboard', pointer: true },
  {
    title: `รายละเอียดแบบทดสอบ`,
    link: `/`,
    pointer: false,
  },
]
